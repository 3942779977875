/* eslint-disable jsx-a11y/anchor-is-valid */
import {Outlet, Route, Routes} from 'react-router-dom'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {SignUp} from './components/SignUp'
import {SetNewPassword} from './components/SetNewPassword'

const AuthLayout = () => {
  return (
    <section className='h-100 '>
      <div
        className='px-4 py-5 px-md-5 text-center text-lg-start h-100 d-flex align-items-center justify-content-center gradient'
        // style={{backgroundColor: ' rgb(245 245 245 / 7%)'}}
      >
        <div className='container'>
          <div className='row gx-lg-5 align-items-center justify-content-between'>
            <div className='col-lg-6 mb-5 mb-lg-0'>
              <h1 className='my-5 display-3 fw-bold ls-tight'>
                The best offer <br />
                <span className='text-primary'>for your business</span>
              </h1>
              <p style={{color: 'hsl(217, 10%, 50.8%)'}}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet, itaque accusantium
                odio, soluta, corrupti aliquam quibusdam tempora at cupiditate quis eum maiores
                libero veritatis? Dicta facilis sint aliquid ipsum atque?
              </p>
            </div>

            <div className='col-lg-6 py-10 mb-lg-0 ' style={{maxWidth: 500}}>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      {/* <Route path='registration' element={<Registration />} /> */}
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='new-password/:uid/:token' element={<SetNewPassword />} />
      <Route path='sign-up' element={<SignUp />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
