import React from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {Button} from 'react-bootstrap'
import Modal from './Modal'
import {useIntl} from 'react-intl'

export default function Ticket () {
  const intl = useIntl()
  return (
    <>
      <div className='w-100 '>
        <div className='d-flex align-items-center justify-content-between mb-5'>
          <h1>{intl.formatMessage({id: 'Ticket'})}</h1>
          <Button
            size='sm'
            className='fs-6'
            type='button'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_ticket'
            // style={{filter: 'blur(3px)'}}
            disabled
          >
            {intl.formatMessage({id: 'New Ticket'})}
          </Button>
        </div>
        <div className='w-100 d-flex gap-10'>
          {/* /////////////////////////////////// */}
          <div className='w-25'>
            <div className='card' 
            // style={{filter: 'blur(3px)'}}
            >
              <div className='menu menu-column menu-rounded menu-state-bg menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary my-5'>
                <div className='menu-item mb-3'>
                  <span className='menu-link '>
                    <span className='menu-icon'>
                      <KTIcon iconName='directbox-default' className='fs-1 me-3' />
                    </span>
                    <span className='menu-title fw-bold'>{intl.formatMessage({id: 'Open'})}</span>
                    <span className='badge badge-light-success'>3</span>
                  </span>
                </div>
                <div className='menu-item mb-3'>
                  <span className='menu-link '>
                    <span className='menu-icon'>
                      <KTIcon iconName='sms' className='fs-1 me-3' />
                    </span>
                    <span className='menu-title fw-bold'>{intl.formatMessage({id: 'Closed'})}</span>
                    <span className='badge badge-light-success'>3</span>
                  </span>
                </div>
                <div className='menu-item mb-3'>
                  <span className='menu-link '>
                    <span className='menu-icon'>
                      <KTIcon iconName='loading' className='fs-1 me-3' />
                    </span>
                    <span className='menu-title fw-bold'>
                      {intl.formatMessage({id: 'In Progress'})}
                    </span>
                    <span className='badge badge-light-success'>3</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* /////////////////////////////////// */}
          <div className='w-75 '>
            <div className='card' 
            // style={{filter: 'blur(3px)'}}
            >
              <div className='card-header align-items-center p-5 gap-2 gap-md-5'>
                <div className='d-flex flex-wrap gap-2 w-100'>
                  <div className='card-body p-0'>
                    <div
                      id='kt_inbox_listing_wrapper'
                      className='dt-container dt-bootstrap5 dt-empty-footer'
                    >
                      <div id='' className='table-responsive'>
                        <table
                          className='table table-hover table-row-dashed fs-6 gy-5 my-0 dataTable'
                          id='kt_inbox_listing'
                        >
                          <thead className='d-none'>
                            <tr>
                              <th
                                data-dt-column='3'
                                className='dt-orderable-asc dt-orderable-desc'
                                aria-label='Title: Activate to sort'
                              >
                                <span className='dt-column-title' role='button'>
                                  Title
                                </span>
                                <span className='dt-column-order'></span>
                              </th>
                              <th
                                data-dt-column='4'
                                className='dt-orderable-asc dt-orderable-desc'
                                aria-label='Date: Activate to sort'
                              >
                                <span className='dt-column-title' role='button'>
                                  Date
                                </span>
                                <span className='dt-column-order'></span>
                              </th>
                            </tr>
                          </thead>

                          <tbody className='w-100'>
                            <tr className='w-100'>
                              <td className='w-150px w-md-175px bg-with rounded-0 ps-3'>
                                <a href='#' className='d-flex align-items-center text-gray-900'>
                                  <div className='symbol symbol-35px me-3'>
                                    <div className='symbol-label bg-light-danger'>
                                      <span className='text-danger'>M</span>
                                    </div>
                                  </div>

                                  <span className='fw-semibold'>Melody Macy</span>
                                </a>
                              </td>

                              <td className='rounded-0'>
                                <div className='text-gray-900 gap-1 pt-2'>
                                  <a href='#' className='text-gray-900'>
                                    <span className='fw-bold'>
                                      Digital PPV Customer Confirmation
                                    </span>

                                    <span className='fw-bold d-none d-md-inine'>- </span>

                                    <span className='d-none d-md-inine text-muted'>
                                      Thank you for ordering UFC 240 Holloway vs Edgar Alternate
                                      camera angles...
                                    </span>
                                  </a>
                                </div>

                                <div className='badge badge-light-primary mt-2 ms-1'>inbox</div>

                                <div className='badge badge-light-warning mt-2 ms-1'>task</div>
                              </td>

                              <td className='w-100px text-end fs-7 pe-9 rounded-0'>
                                <span className='fw-semibold'>8:30 PM</span>
                              </td>
                            </tr>
                            <tr className='w-100'>
                              <td className='w-150px w-md-175px bg-with rounded-0 ps-3'>
                                <a href='#' className='d-flex align-items-center text-gray-900'>
                                  <div className='symbol symbol-35px me-3'>
                                    <div className='symbol-label bg-light-danger'>
                                      <span className='text-danger'>M</span>
                                    </div>
                                  </div>

                                  <span className='fw-semibold'>Melody Macy</span>
                                </a>
                              </td>

                              <td className='rounded-0'>
                                <div className='text-gray-900 gap-1 pt-2'>
                                  <a href='#' className='text-gray-900'>
                                    <span className='fw-bold'>
                                      Digital PPV Customer Confirmation
                                    </span>

                                    <span className='fw-bold d-none d-md-inine'>- </span>

                                    <span className='d-none d-md-inine text-muted'>
                                      Thank you for ordering UFC 240 Holloway vs Edgar Alternate
                                      camera angles...
                                    </span>
                                  </a>
                                </div>

                                <div className='badge badge-light-primary mt-2 ms-1'>inbox</div>

                                <div className='badge badge-light-warning mt-2 ms-1'>task</div>
                              </td>

                              <td className='w-100px text-end fs-7 pe-9 rounded-0'>
                                <span className='fw-semibold'>8:30 PM</span>
                              </td>
                            </tr>
                            <tr className='w-100'>
                              <td className='w-150px w-md-175px bg-with rounded-0 ps-3'>
                                <a href='#' className='d-flex align-items-center text-gray-900'>
                                  <div className='symbol symbol-35px me-3'>
                                    <div className='symbol-label bg-light-danger'>
                                      <span className='text-danger'>M</span>
                                    </div>
                                  </div>

                                  <span className='fw-semibold'>Melody Macy</span>
                                </a>
                              </td>

                              <td className='rounded-0'>
                                <div className='text-gray-900 gap-1 pt-2'>
                                  <a href='#' className='text-gray-900'>
                                    <span className='fw-bold'>
                                      Digital PPV Customer Confirmation
                                    </span>

                                    <span className='fw-bold d-none d-md-inine'>- </span>

                                    <span className='d-none d-md-inine text-muted'>
                                      Thank you for ordering UFC 240 Holloway vs Edgar Alternate
                                      camera angles...
                                    </span>
                                  </a>
                                </div>

                                <div className='badge badge-light-primary mt-2 ms-1'>inbox</div>

                                <div className='badge badge-light-warning mt-2 ms-1'>task</div>
                              </td>

                              <td className='w-100px text-end fs-7 pe-9 rounded-0'>
                                <span className='fw-semibold'>8:30 PM</span>
                              </td>
                            </tr>
                            <tr className='w-100'>
                              <td className='w-150px w-md-175px bg-with rounded-0 ps-3'>
                                <a href='#' className='d-flex align-items-center text-gray-900'>
                                  <div className='symbol symbol-35px me-3'>
                                    <div className='symbol-label bg-light-danger'>
                                      <span className='text-danger'>M</span>
                                    </div>
                                  </div>

                                  <span className='fw-semibold'>Melody Macy</span>
                                </a>
                              </td>

                              <td className='rounded-0'>
                                <div className='text-gray-900 gap-1 pt-2'>
                                  <a href='#' className='text-gray-900'>
                                    <span className='fw-bold'>
                                      Digital PPV Customer Confirmation
                                    </span>

                                    <span className='fw-bold d-none d-md-inine'>- </span>

                                    <span className='d-none d-md-inine text-muted'>
                                      Thank you for ordering UFC 240 Holloway vs Edgar Alternate
                                      camera angles...
                                    </span>
                                  </a>
                                </div>

                                <div className='badge badge-light-primary mt-2 ms-1'>inbox</div>

                                <div className='badge badge-light-warning mt-2 ms-1'>task</div>
                              </td>

                              <td className='w-100px text-end fs-7 pe-9 rounded-0'>
                                <span className='fw-semibold'>8:30 PM</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal />
    </>
  )
}
