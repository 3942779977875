import React from 'react'
import {
  httpRequestsIngress_LineChart,
  httpStatusCode_tag,
  latencyAveragePercentiles_LineChart,
  latencyAveragePercentiles_tag,
  newConnections_ColumnChart,
  totalConnection_LineChart,
  totalHttpRequest_ColumnChart,
} from '../../modules/auth/redux/DEMO_DATA'
import CardProvider from './components/CardProvider'
import Overview from './components/Overview'
import {LineChart} from './components/LineChart'
import OverviewTag from './components/OverviewTag'
import {ColumnChart} from './components/ColumnChart'
import {HeatmapChart} from './components/HeatmapChart'
import Modal from './components/Modal'
import {useIntl} from 'react-intl'

export default function Dos () {
  const intl = useIntl()
  return (
    <div className='w-100 d-flex flex-column gap-5 '>
      <div className=' d-flex flex-column gap-5'>
        <div className='d-flex align-items-center justify-content-between'>
          <h1>{intl.formatMessage({id: 'Overview'})}</h1>
          <button
            className='btn btn-primary w-200px'
            type='button'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_edit'
          >
            {intl.formatMessage({id: 'Dos Running'})}
          </button>
        </div>
        <Overview />
        <div className='d-flex gap-2 w-100'>
          <CardProvider
          
            title={intl.formatMessage({id: 'HTTP Requests / Ingress'})}
            className=' w-100 px-5 h-400px text-nowrap border-1 border-gray-300'
          >
            <LineChart chartData={httpRequestsIngress_LineChart} />
          </CardProvider>
          <CardProvider
            title={intl.formatMessage({id: 'HTTP Status Codes'})}
            className=' w-100 h-400px px-5 text-nowrap border-1 border-gray-300 '
          >
            <div className='h-100'>
              <div className='h-75'>
                <LineChart chartData={httpRequestsIngress_LineChart} />
              </div>
              <OverviewTag />
            </div>
          </CardProvider>
          <CardProvider
            title={intl.formatMessage({id: 'Total HTTP Requests'})}
            className=' w-100 h-400px px-5 text-nowrap border-1 border-gray-300'
          >
            <ColumnChart color={'#02694a'} chartData={totalHttpRequest_ColumnChart} />
          </CardProvider>
        </div>
      </div>
      <div className=' '>
        <h1>{intl.formatMessage({id: 'Latency'})}</h1>
        <div className='d-flex gap-2 w-100'>
          <CardProvider
            title={intl.formatMessage({id: 'Latency (Average Percentiles)'})}
            className=' w-100 px-5 h-400px text-nowrap border-1 border-gray-300'
          >
            <div className='d-flex gap-5 w-100 h-100'>
              <div className='w-50 h-100'>
                <LineChart chartData={latencyAveragePercentiles_LineChart} />
              </div>
              <div className='w-50 h-100'>
                <div className='  w-100 d-flex gap-1 mt-4'>
                  <div className='w-50 d-flex align-items-center gap-2 align-items-center'></div>
                  <span className='w-25  text-center text-primary'>min</span>
                  <span className='w-25  text-center text-primary'>max</span>
                  <span className='w-25  text-center text-primary'>avg</span>
                </div>
                {latencyAveragePercentiles_tag.map((item, ind) => (
                  <div className='  w-100 d-flex gap-1 mt-4' key={ind}>
                    <div className='w-50 d-flex align-items-center gap-2 align-items-center'>
                      <div
                        className=' h-5px w-20px rounded-pill'
                        style={{backgroundColor: item.color}}
                      ></div>
                      <span className=' text-capitalize'>{item.value}</span>
                    </div>
                    <span className='w-25  text-center'>{item.min}</span>
                    <span className='w-25  text-center'>{item.max}</span>
                    <span className='w-25  text-center'>{item.ave}</span>
                  </div>
                ))}
              </div>
            </div>
          </CardProvider>
          <CardProvider
            title={intl.formatMessage({id: 'Latency Heatmap'})}
            className=' w-100 px-5 h-400px text-nowrap border-1 border-gray-300'
          >
            <HeatmapChart chartData={httpRequestsIngress_LineChart} />
          </CardProvider>
        </div>
      </div>
      <div className=' d-flex flex-column gap-5'>
        <h1>{intl.formatMessage({id: 'Connections'})}</h1>
        <div className='d-flex gap-2 w-100'>
          <CardProvider
            title={intl.formatMessage({id: 'New Connections Opened (Controller / Ingress Pod)'})}
            className=' w-100 px-5 h-400px text-nowrap border-1 border-gray-300'
          >
            <ColumnChart color={'#d2ae09'} chartData={newConnections_ColumnChart} />
          </CardProvider>

          <CardProvider
            title={intl.formatMessage({id: 'Total Connections Opened (Controller / Ingress Pod)'})}
            className=' w-100 h-400px px-5 text-nowrap border-1 border-gray-300'
          >
            <LineChart chartData={totalConnection_LineChart} />
          </CardProvider>
        </div>
      </div>
      <Modal />
    </div>
  )
}
