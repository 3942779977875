import React, {useEffect, useLayoutEffect, useState} from 'react'
import ReactPaginate from 'react-paginate'
import axios from 'axios'
import {useDispatch} from 'react-redux'
import {useIntl} from 'react-intl'
import useErrorHandling from '../../../../Utils/useErrorHandling'
import PageLoading from '../../loading/PageLoading'
import {DetailsDrawer} from './DetailsDrawer'
import {EXPLORE_IPS_DATA as IPsData} from '../../../modules/auth/redux/DEMO_DATA'
import {FilterSelectedIp, SearchIp, SetDataIp, SortIp} from '../../../modules/auth'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../setup/redux/Store'
import useGetFilterOptionsIp from '../../../../Utils/useGetFilterOptionsIp'
import SearchAndSort from '../../components/SearchAndSort'
const SortOptions = [
  {value: 'ip', name: 'Ip'},
  {value: 'loc', name: 'Location'},
  {value: 'version', name: 'Version'},
]
export default function IPs () {
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState<number | null>(null)
  const errorHandling = useErrorHandling()
  const dispatch = useDispatch()
  const getFilterOptionsIp = useGetFilterOptionsIp()
  const intl = useIntl()
  const ipData = useSelector((state: RootState) => state.root.data.dataIp)
  const user = useSelector((state: RootState) => state.root.user)
  const filterOptionIp = useSelector((state: RootState) => state?.root?.filterOption?.ip.options)
  const selected = useSelector((state: RootState) => state?.root?.filterOption?.ip.selected)
  const sort = useSelector((state: RootState) => state.root.filterOption.ip)
  const search = useSelector((state: RootState) => state.root.filterOption.ip.search)
  function createPropsSort (sortProps: string) {
    if (sortProps) {
      return sortProps
    }
    if (sort.sortAsc) {
      return `&ordering=${sort.sortAsc}`
    } else if (sort.sortDes) {
      return `&ordering=${sort.sortDes}`
    }
    return ''
  }
  function createPropsSearch () {
    return search.map((item) => `&${item.name}=${item.value}`).join('')
  }
  const handlePageClick = (event: {selected: number}) => {
    fetchIpData({query: `?page=${event.selected + 1}`})
  }
  function selectedHandel (items: string, item: string) {
    const copy = structuredClone(selected)
    if (selected[items] && selected[items].length && selected[items].includes(item)) {
      copy[items] = copy[items].filter((it: string | number) => it !== item)
    } else {
      if (copy[items]?.length) {
        copy[items].push(item)
      } else {
        copy[items] = [item]
      }
    }
    dispatch(FilterSelectedIp(copy))
    if (process.env.REACT_APP_MODE !== 'demo') {
      fetchIpData({query: '?page=1', filterProps: copy})
    }
  }
  function createPropsFilter (props?: any) {
    if (props) {
      const keys = Object.keys(props)
      const text = keys.map((key) => {
        if (props[key] && props[key].length) {
          return `&${key}=${props[key].join(',')}`
        } else {
          return ''
        }
      })

      const prop = text.join('')

      return prop
    } else {
      const keys = Object.keys(selected)
      const text = keys.map((key) => {
        if (selected[key] && selected[key].length) {
          return `&${key}=${selected[key].join(',')}`
        } else {
          return ''
        }
      })

      const prop = text.join('')

      return prop
    }
  }

  const fetchIpData = async ({query = '?page=1', filterProps = '', sort = ''}) => {
    setLoading(true)
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/ip/${query}${createPropsFilter(
          filterProps
        )}${createPropsSort(sort)}${createPropsSearch()}`
      )
      errorHandling(data.status) //logout when invalid token && redirect 404
      if (data.status >= 200 && data.status < 300) {
        dispatch(SetDataIp(data.data))
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/ip/${query}${filterProps} \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  useLayoutEffect(() => {
    const nowDate = new Date().getTime()
    if (ipData?.expireTime && ipData.expireTime >= nowDate) {
    } else {
      fetchIpData({})
    }
    if (!filterOptionIp && process.env.REACT_APP_MODE !== 'demo') {
      getFilterOptionsIp()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className={`p-4 bg-body ${loading && 'skeleton'}`} style={{width: 'calc(100% - 100px)'}}>
        <h1>{intl.formatMessage({id: 'IPs'})}</h1>
        <div className='w-100  d-flex pt-5 position-relative'>
          <div
            className=' w-25   border-end border-start border-gray pe-4 d-flex flex-column gap-4 '
            style={!filterOptionIp && !user?.is_user_scan ? {filter: 'blur(3px)'} : {}}
          >
            {filterOptionIp ? (
              Object.keys(filterOptionIp)?.map((items: string, index: number) => {
                return (
                  <div key={index} className='d-flex flex-column'>
                    <h5 className='m-0 ' style={{textTransform: 'capitalize'}}>
                      {intl.formatMessage({id: items})}
                    </h5>
                    {!!filterOptionIp[items]?.length ? (
                      filterOptionIp[items]
                        .slice(
                          0,
                          filterOptionIp[items]?.length > 10
                            ? open === index
                              ? filterOptionIp[items]?.length - 1
                              : 10
                            : filterOptionIp[items]?.length
                        )
                        .map((item: any, ind: number) => (
                          <div className='w-100' key={ind}>
                            <hr className='my-2 mx-0 ' />
                            <div
                              style={{cursor: 'pointer'}}
                              onClick={(e) => {
                                if (items === 'scores') {
                                  selectedHandel(items, item)
                                } else {
                                  selectedHandel(items, item.name)
                                }
                              }}
                              className='form-check form-check-custom w-100 form-check-solid form-check-sm  p-1 rounded item_hover '
                            >
                              <input
                                className='form-check-input'
                                type='checkbox'
                                readOnly
                                checked={
                                  items === 'scores'
                                    ? selected[items]?.includes(item)
                                    : selected[items]?.includes(item.name)
                                }
                              />
                              <div
                                className='form-check-label w-100 d-flex justify-content-between mx-2 gap-2'
                                style={{maxWidth: ' calc(100% - 33px)'}}
                              >
                                <span className='d-flex  mw-50 text_hide_width text-nowrap'>
                                  {items === 'scores' ? item : item.name}
                                </span>
                                <span>{items === 'scores' ? '' : item.count}</span>
                              </div>
                            </div>
                          </div>
                        ))
                    ) : (
                      <span>N/A</span>
                    )}
                    {filterOptionIp[items]?.length - 1 > 10 &&
                      (index !== open ? (
                        <span
                          style={{alignSelf: 'center', cursor: 'pointer'}}
                          onClick={() => {
                            setOpen(index)
                          }}
                        >
                          {intl.formatMessage({id: 'show more'})}
                        </span>
                      ) : (
                        <span
                          style={{alignSelf: 'center', cursor: 'pointer'}}
                          onClick={() => {
                            setOpen(null)
                          }}
                        >
                          {intl.formatMessage({id: 'show less'})}
                        </span>
                      ))}
                  </div>
                )
              })
            ) : (
              <PageLoading />
            )}
          </div>
          <div className={`w-75 ps-3 `}>
            <SearchAndSort
              className={'mb-5'}
              search={search}
              setSearch={SearchIp}
              sort={sort}
              setSort={SortIp}
              getData={fetchIpData}
              sortOption={SortOptions}
              searchOption={Object.keys(filterOptionIp || {})}
            />
            <div className={`w-100  overflow-visible  m-0 pb-8`}>
              <div className='d-flex flex-column align-items-center mw-100'>
                {/* start title */}

                <div className='w-100 mw-100 d-flex fs-5 fw-bold bg-gray-400 rounded-1 mb-4 p-2 pt-1 pb-1 justify-content-between align-items-center'>
                  <div className=' p-1 h-auto' style={{width: 120}}>
                    <div className='w-100  h-auto d-flex justify-content-start align-items-center pointer'>
                      <span>{intl.formatMessage({id: 'IP Address'})}</span>
                    </div>
                  </div>
                  <div className='w-50 mw-50 p-1 h-auto d-flex justify-content-start'>
                    <div className='w-100  h-auto d-flex justify-content-start align-items-center pointer'>
                      {' '}
                      <span>{intl.formatMessage({id: 'Domain'})}</span>{' '}
                    </div>
                  </div>

                  <div className='w-100px d-flex justify-content-start p-1 ms-2 h-auto'>
                    {intl.formatMessage({id: 'Version'})}
                  </div>
                  <div className='w-100px p-1 ms-2 h-auto d-flex justify-content-start'>
                    <div className='w-100 h-auto d-flex justify-content-start align-items-center pointer'>
                      {' '}
                      <span>{intl.formatMessage({id: 'Location'})}</span>{' '}
                    </div>
                  </div>
                </div>

                {/* end titles */}
                {ipData?.data?.results?.map((item: any, index: number) => (
                  <div
                    onClick={() => setOpen(item.id)}
                    style={{}}
                    key={index}
                    className={`w-100 m-0 p-0 h-auto bg-gray-200 rounded-1 d-flex justify-content-start align-items-center mb-3 hover_Costume`}
                  >
                    <div className='w-100 d-flex p-2 pt-1 pb-1 justify-content-between align-items-center'>
                      <div
                        className=' p-1   h-auto text-nowrap text_hide_width'
                        style={{width: 120}}
                      >
                        {' '}
                        {item?.ip}{' '}
                      </div>
                      <div className='w-50 p-1 h-auto d-flex justify-content-start text-nowrap text_hide_width'>
                        {item?.host_domain}
                      </div>
                      <div className='w-100px d-flex justify-content-start align-items-center p-1 h-auto text-uppercase'>
                        {item?.version}
                        {item.live}
                      </div>
                      <div className='w-100px p-1 ms-2 h-auto d-flex justify-content-start'>
                        {item?.loc}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className='d-flex justify-content-end bg-body  pe-4 rounded-bottom'>
                <ReactPaginate
                  nextLabel={`${intl.formatMessage({id: 'Next'})} >`}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  pageCount={ipData?.data?.total_pages || 1}
                  forcePage={ipData?.data?.current_page - 1 || 0}
                  previousLabel={`< ${intl.formatMessage({id: 'Previous'})}`}
                  pageClassName='page-item'
                  pageLinkClassName='page-link'
                  previousClassName='page-item'
                  previousLinkClassName='page-link'
                  nextClassName='page-item'
                  nextLinkClassName='page-link'
                  breakLabel='...'
                  breakClassName='page-item'
                  breakLinkClassName='page-link'
                  containerClassName='pagination'
                  activeClassName='active'
                  renderOnZeroPageCount={null}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <DetailsDrawer id={open} setId={setOpen} />
    </>
  )
}
