import {useEffect, useLayoutEffect, useState} from 'react'
import CardProvider from './Card/CardProvider'
import Chart from './Card/Chart'
import Footer from './Card/Footer'
import Header from './Card/Header'
import axios from 'axios'
import useErrorHandling from '../../../Utils/useErrorHandling'
import {useDispatch} from 'react-redux'
import {SetDataExposures} from '../../modules/auth'
import {useSelector} from 'react-redux'
import {RootState} from '../../../setup/redux/Store'
import PageLoading from '../loading/PageLoading'
import img11 from '../images/11.svg'
import img22 from '../images/22.svg'
import img33 from '../images/33.svg'
import img44 from '../images/44.svg'
import img66 from '../images/66.svg'
import img77 from '../images/77.svg'
import {ATTACK_SURFACE_CARDS} from '../../modules/auth/redux/DEMO_DATA'
import Loc from '../images/Loc'
import {useIntl} from 'react-intl'
import Port from '../images/Port'
import Bug from '../images/Bug'
import Time from '../images/Time'

export default function AttackSurface () {
  const [loading, setLoading] = useState(false)
  const errorHandling = useErrorHandling()
  const dataExposures = useSelector((state: RootState) => state.root.data.dataExposures)
  const intl = useIntl()
  const dispatch = useDispatch()
  const fetchAttackSurface = async () => {
    setLoading(true)
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/attack-surface/`)
      errorHandling(data.status) //logout when invalid token && redirect 404
      if (data.status >= 200 && data.status < 300) {
        console.log(data)
        dispatch(SetDataExposures(data.data))
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/attack-surface/ \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }
  useLayoutEffect(() => {
    const nowDate = new Date().getTime()
    if (dataExposures?.expireTime && dataExposures.expireTime >= nowDate) {
    } else {
      fetchAttackSurface()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const {DNS, port, ssl, mail, technologies, leaked, domains, ip, app} = ATTACK_SURFACE_CARDS
  return (
    <>
      <div className='d-flex  w-100 mt-5 flex-wrap gap-10 mb-10 justify-content-center '>
        <div
          className='d-flex flex-wrap flex-column gap-10 justify-content-start'
          style={{minWidth: 370}}
        >
          {/* /////////////Domains///////////// */}
          <CardProvider loading={loading} link={domains.link}>
            <Header
              icon={domains.icon}
              name={domains.title}
              count={dataExposures?.data?.domains?.hosts_count}
            />
            <div
              style={
                dataExposures?.data?.domains?.hosts_count === null ? {filter: 'blur(3px)'} : {}
              }
            >
              <div style={{filter: 'blur(3px)'}}>
                <Chart list={domains.chart} type='column' />
              </div>

              {/* <Footer list={domains.footer} /> */}
              <hr />
              <div className='card_footer '>
                <div style={{width: '100%'}}>
                  <Loc className='svg-icon svg-icon-4x image_change_theme2' />
                  <div>
                    <span>{intl.formatMessage({id: 'IPs'})}</span>
                    <strong>{dataExposures.data?.domains?.ipv4s_count || ''}</strong>
                  </div>
                </div>
                <div style={{width: '100%'}}>
                  <Port className='svg-icon svg-icon-4x image_change_theme2' />
                  <div>
                    <span>{intl.formatMessage({id: 'Ports'})}</span>
                    <strong>{dataExposures.data?.domains?.ports_count || ''}</strong>
                  </div>
                </div>
                <div style={{width: '100%'}}>
                  <Bug className='svg-icon svg-icon-4x image_change_theme2' />
                  <div>
                    <span>{intl.formatMessage({id: 'Vulns'})}</span>
                    <strong>{dataExposures.data?.domains?.ipv4s_count || ''}</strong>
                  </div>
                </div>
              </div>
            </div>
          </CardProvider>
          {/* ////////////ports////////////// */}
          <CardProvider loading={loading} link={port.link}>
            <Header icon={port.icon} name={port.title} count={null} />
            <div style={{filter: 'blur(3px)'}}>
              <Chart list={port.chart} type='line' />
              <Footer list={port.footer} />
            </div>
          </CardProvider>
        </div>
        <div
          className='d-flex flex-wrap flex-column gap-10  justify-content-start'
          style={{minWidth: 370}}
        >
          {/* ////////////IPs////////////// */}
          <CardProvider loading={loading} link={ip.link}>
            <Header icon={ip.icon} name={ip.title} count={dataExposures.data?.ip?.ip_count} />
            <div style={{filter: 'blur(3px)'}}>
              <Chart list={ip.chart} type='column' />
              <Footer list={ip.footer} />
            </div>
          </CardProvider>
          {/* ////////technologies/////////// */}
          <CardProvider loading={loading} link={technologies.link}>
            <Header
              icon={technologies.icon}
              name={technologies.title}
              count={dataExposures?.data?.techs?.techs_count}
            />
            <div
              style={{filter: 'blur(3px)'}}
              // style={dataExposures?.data?.techs?.techs_count === 0 ? {filter: 'blur(3px)'} : {}}
            >
              <hr />
              <div className='d-flex gap-5 flex-wrap justify-content-between p-5'>
                <img src={img11} width={90} height={55} />
                <img src={img22} width={90} height={55} />
                <img src={img33} width={90} height={55} />
                <img src={img44} width={90} height={55} />
                <img src={img77} width={90} height={55} />
                <img src={img66} width={90} height={55} />
              </div>
            </div>
          </CardProvider>
          {/* ////////////Email Add/////////// */}
          <CardProvider loading={loading} link={mail.link}>
            <Header
              icon={mail.icon}
              name={mail.title}
              count={dataExposures.data?.email?.email_count}
            />
          </CardProvider>
        </div>
        <div
          className='d-flex flex-wrap flex-column gap-10  justify-content-start'
          style={{minWidth: 370}}
        >
          {/* ////////////Applications/////////////// */}
          <CardProvider loading={loading} link={app.link}>
            <Header icon={app.icon} name={app.title} count={null} />
            <div style={{filter: 'blur(3px)'}}>
              <Footer list={app.footer} />
            </div>
          </CardProvider>
          {/* ////////////Leaked Data/////////// */}
          <CardProvider loading={loading} link={leaked.link}>
            <Header icon={leaked.icon} name={leaked.title} count={''} />
          </CardProvider>
          {/* /////////////DNS//////////////// */}
          <CardProvider loading={loading} link={DNS.link}>
            <Header
              icon={DNS.icon}
              name={DNS.title}
              count={dataExposures.data?.dns_records?.total}
            />
            <div
              style={
                !dataExposures.data?.dns_records?.A || !dataExposures.data?.dns_records?.CNAME
                  ? {filter: 'blur(3px)'}
                  : {}
              }
            >
              <Footer
                list={[
                  {
                    name: 'A',
                    value: dataExposures.data?.dns_records?.A || 0,
                    icon: DNS.footer[0].icon,
                  },
                  {
                    name: 'CNAME',
                    value: dataExposures.data?.dns_records?.CNAME || 0,
                    icon: DNS.footer[0].icon,
                  },
                ]}
              />
            </div>
          </CardProvider>
          {/* /////////////SSL//////////////// */}
          <CardProvider loading={loading} link={ssl.link}>
            <Header icon={ssl.icon} name={ssl.title} count={dataExposures?.data?.ssl?.ssl_count} />
            <Footer
              list={[
                {
                  name: 'Expiring',
                  value: dataExposures.data?.ssl?.expiring_ssl_count || 0,
                  icon: <Time className='svg-icon svg-icon-4x image_change_theme2' />,
                },
              ]}
            />
          </CardProvider>
        </div>
      </div>
    </>
  )
}
