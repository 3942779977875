// Redux
// https://github.com/rt2zz/redux-persist
import {Provider} from 'react-redux'
import * as _redux from './setup'
import {store} from './setup/redux/Store'
// Axios
import axios from 'axios'
// import {Chart, registerables} from 'chart.js'
import ReactDOM from 'react-dom/client'
// Apps
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/

// import './_metronic/assets/sass/style.scss'
// import './_metronic/assets/sass/style.dark.scss'
import './_metronic/assets/sass/style.react.scss'
import './_metronic/assets/fonticon/fonticon.css'
import './_metronic/assets/keenicons/duotone/style.css'
import './_metronic/assets/keenicons/outline/style.css'
import './_metronic/assets/keenicons/solid/style.css'
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/customeClass.scss'
import './_metronic/assets/sass/rtl.scss'
import {AppRoutes} from './app/routing/AppRoutes'
import React from 'react'
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
_redux.setupAxios(axios, store)

// Chart.register(...registerables)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <MetronicI18nProvider>
      <Provider store={store}>
        <AppRoutes />
      </Provider>
    </MetronicI18nProvider>
  </React.StrictMode>
)
