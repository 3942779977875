
import ReactFlow, {
  Controls,
  MarkerType,
  useNodesState,
  useEdgesState,
  Edge,
  Node,
  Position,
} from 'react-flow-renderer'
import '../../workFlow/components/WorkFlow.scss'
import CustomEdge from '../../workFlow/components/CustomEdge'
import CustomNode from '../../workFlow/components/CustomNode'
interface CustomEdge extends Edge {
  markerEnd?: string // Define markerEnd as an optional string
}
const edgeTypes = {
  custom: CustomEdge,
}
const nodeTypes = {
  custom: CustomNode,
}

const initialNodes: Node[] = [
  {
    id: '1',
    type: 'input',
    data: {label: <div className='label'>Initial</div>},
    position: {y: 250, x: 0},
    sourcePosition: Position.Right, // Use imported Position type
    className: 'large_item',
  },
  {
    id: '2',
    type: 'output',
    data: {label: <div className='label'>DNS</div>},
    position: {y: 100, x: 200},
    targetPosition: Position.Left, // Use imported Position type
    className: 'large_item',
  },
  {
    id: '3',
    type: 'output',
    data: {label: <div className='label'>SMTP</div>},
    position: {y: 400, x: 200},
    targetPosition: Position.Left,
    className: 'large_item',
  },
  {
    id: '4',
    data: {label: <div className='label'>Assets</div>},
    position: {y: 250, x: 200},
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
    className: 'large_item',
  },
  {
    id: '5',
    type: 'output',
    data: {label: <div className='label'>PScan</div>},
    position: {y: 100, x: 500},
    targetPosition: Position.Left,
    className: 'large_item',
  },
  {
    id: '6',
    type: 'output',
    data: {label: <div className='label'>IP Info</div>},
    position: {y: 200, x: 500},
    targetPosition: Position.Left,
    className: 'large_item',
  },
  {
    id: '7',
    data: {label: <div className='label'>lf</div>},
    position: {y: 300, x: 500},
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
    className: 'large_item',
  },
  {
    id: '8',
    type: 'output',
    data: {label: <div className='label'>SSL</div>},
    position: {y: 400, x: 500},
    targetPosition: Position.Left,
    className: 'large_item',
  },
  {
    id: '9',
    type: 'output',
    data: {label: <div className='label'>Screen Shot</div>},
    position: {y: 300, x: 700},
    targetPosition: Position.Left,
    className: 'large_item',
  },
]

const initialEdges: CustomEdge[] = [
  {
    id: '1-2',
    source: '1',
    sourceHandle: null,
    target: '2',
    targetHandle: null,
  },
  {
    id: '1-3',
    source: '1',
    target: '3',
  },
  {
    id: '1-4',
    source: '1',
    target: '4',
  },
  {
    id: '4-5',
    source: '4',
    target: '5',
  },
  {
    id: '4-6',
    source: '4',
    target: '6',
  },
  {
    id: '4-7',
    source: '4',
    target: '7',
  },
  {
    id: '4-8',
    source: '4',
    target: '8',
  },
  {
    id: '7-9',
    source: '7',
    target: '9',
  },
]
const DAG = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes)
  const [edges, setEdges, onEdgesChange] = useEdgesState(
    initialEdges?.map((item) => {
      item.markerEnd = MarkerType.Arrow
      item.style = {cursor: 'pointer'}
      return item
    })
  )
  return (
    <>
      {/* {start workflow} */}
      <div style={{width: '100%', height: '100% ', direction: 'ltr'}}>
        <ReactFlow
          nodeTypes={nodeTypes}
          nodes={nodes}
          edges={edges}
          snapToGrid={true}
          edgeTypes={edgeTypes}
          fitView
        >
          {/* <Background />*/}
          <Controls />
        </ReactFlow>
      </div>
      {/* {end workflow} */}
    </>
  )
}

export default DAG
