import React from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import axios from 'axios'
import clsx from 'clsx'

export default function Modal () {
  const intl = useIntl()
  const formik = useFormik({
    initialValues: {ticket: ''},

    onSubmit: (values) => {
      //   axios
      //     .patch(`${process.env.REACT_APP_API_ENDPOINT}/scan/${id}/`, values)
      //     .then((res) => {
      //       if (res.status >= 200 && res.status < 300) {
      //       } else {
      //       }
      //     })
      //     .catch((e) => {
      //       console.log('message \n', e?.response?.data?.error)
      //     })
    },
  })
  return (
    <>
      <div className='modal fade ' id='kt_modal_ticket' style={{backdropFilter: 'blur(3px)'}}>
        <div className='modal-dialog' style={{minWidth: '50%'}}>
          <div className='modal-content'>
            <div className='modal-header'>
              <h3 className='modal-title'> {intl.formatMessage({id: 'New Ticket'})}</h3>

              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon-2 image_change_theme2'
                />
              </div>
            </div>

            <div className='modal-body'>
              <form
                className='w-100 form'
                onSubmit={formik.handleSubmit}
                noValidate
                id='kt_login_signin_form'
              >
                <>
                  <div className='fv-row mb-10'>
                    <label className='form-label fs-6 fw-bolder'>
                      {intl.formatMessage({id: 'Ticket'})}
                    </label>
                    <textarea
                      placeholder={intl.formatMessage({id: 'Ticket'})}
                      {...formik.getFieldProps('ticket')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid': formik.touched.ticket && formik.errors.ticket,
                        },
                        {
                          'is-valid': formik.touched.ticket && !formik.errors.ticket,
                        }
                      )}
                      name='ticket'
                      autoComplete='off'
                    />
                    {formik.touched.ticket && formik.errors.ticket && (
                      <div className='fv-plugins-message-container mt-2 text-danger'>
                        <span role='alert'>{formik.errors.ticket}</span>
                      </div>
                    )}
                  </div>
                  {process.env.REACT_APP_MODE === 'demo' && (
                    <p className='text-danger'>
                      {' '}
                      {intl.formatMessage({id: 'This feature is not available in demo mode'})}
                    </p>
                  )}
                  <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-success'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                    disabled={process.env.REACT_APP_MODE === 'demo'}
                  >
                    {intl.formatMessage({id: 'Send Ticket'})}
                  </button>
                </>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
