import {EdgeProps, getBezierPath} from 'react-flow-renderer'

export default function CustomEdge ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {cursor: 'pointer'},
  data,
  markerEnd,
}: EdgeProps) {
  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  })

  return (
    <>
      <path
        id={id}
        style={{cursor: 'pointer'}}
        className='react-flow__edge-path'
        d={edgePath}
        markerEnd={markerEnd}
      />
      <text></text>
    </>
  )
}
