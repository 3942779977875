import React, {useState, useEffect} from 'react'
import axios from 'axios'

import {useDispatch, useSelector} from 'react-redux'

import {useIntl} from 'react-intl'
import useErrorHandling from '../../../../Utils/useErrorHandling'
import {useLang} from '../../../../_metronic/i18n/Metronici18n'
import {RootState} from '../../../../setup/redux/Store'
import {SetDataVulnerability} from '../../../modules/auth'
import PageLoading from '../../loading/PageLoading'
import ConvertDate from '../../../../Utils/ConvertDate'
import EmptyPage from '../../emptyPage/EmptyPage'
import { useNavigate } from 'react-router-dom'

export const TaskScan = ({vulnerabilityData}:{vulnerabilityData:any}) => {
  const [loading, setLoading] = useState(false)
  const lang = useLang()?.lang.selectedLang
  const intl = useIntl()
  const navigate= useNavigate()
  return (
    <>
      <>
        <div className='row d-flex flex-row p-3 '>
          <div className='w-100 h-100'>
            <div className={` h-100 rounded-bottom-0`}>
              {/* start body */}
              {loading ? (
                <PageLoading />
              ) : vulnerabilityData && vulnerabilityData?.length ? (
                <div className={`w-100 h-100 overflow-auto  m-0`}>
                  {vulnerabilityData &&
                    vulnerabilityData?.map((item: any, index: number) => (
                      <div
                        key={item?.id}
                        className={`w-100 m-0 p-3  bg-gray-200 rounded-1 d-flex justify-content-center align-items-center mb-3 hover_Costume`}
                        onClick={() => {
                          navigate({
                            pathname: '/vulnerability-details',
                            search: `?id=${item.id}`,
                          })
                        }}
                      >
                        <div className='w-100 d-flex flex-column gap-5'>
                          <div className='w-100 d-flex  justify-content-between align-items-center'>
                            <div className={` text-primary fs-6`}>
                              {lang === 'fa' ? item?.title_fa || 'منتظر داده باشید' : item.title}
                            </div>

                            <div className=' h-auto d-flex justify-content-center p-1'>
                              <ConvertDate date={item.timestamp} jalali={item.timestamp_jalali} />
                            </div>
                          </div>

                          <div className='d-flex gap-3 justify-content-between px-2'>
                            <div className='w-100 h-auto d-flex flex-column gap-1'>
                              <span className='text-gray-800'>
                                {intl.formatMessage({id: 'Target'})}
                              </span>
                              <div className=' d-flex  align-items-center p-1 h-auto'>
                                {item.target}
                              </div>
                            </div>
                            <div className='w-100 h-auto d-flex flex-column gap-1 '>
                              <span className='text-gray-800'>
                                {intl.formatMessage({id: 'Affected Asset'})}
                              </span>
                              <span className='fs-6 text-primary'>{item.asset}</span>
                            </div>
                            <div className='px-2 w-100 d-flex flex-column gap-1'>
                              <span className='text-gray-800 ms-2'>
                                {intl.formatMessage({id: 'Severity'})}
                              </span>
                              <div className='  h-auto d-flex flex-row align-items-center  text-capitalize'>
                                <div className=' d-flex justify-content-start'>
                                  <div
                                    className={`w-15px h-15px  rounded-3 border-i${item.severity} border border-2 m-0 `}
                                  />
                                </div>
                                {intl.formatMessage({id: item.severity||'low'})}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              ) : (
                <EmptyPage />
              )}
              {/* end body */}
            </div>
          </div>
        </div>
      </>
    </>
  )
}

// <div
//   key={item.id}
//   className={`w-100 m-0 p-0 h-auto bg-gray-200 rounded-1 d-flex align-items-center ${
//     index === vulnerabilityData?.data?.results?.length - 1 ? null : 'mb-3'
//   } hover_Costume`}
//   style={{cursor: 'pointer', minHeight: 64}}
// >
//   <div className='w-100 d-flex p-1 align-items-center'>
//     <div className='w-50 p-1 h-auto d-flex align-items-center'>
//       {' '}
//       {lang === 'fa' ? item?.title_fa || 'منتظر داده باشید' : item.title}{' '}
//     </div>
//     <div className='w-25 p-1 ms-3 h-auto d-flex flex-row align-items-center  text-capitalize'>
//       <div className=' d-flex justify-content-end'>
//         <div
//           className={`w-15px h-15px me-2 rounded-3 border-i${item.severity} border border-2 `}
//         />
//       </div>
//       {item.severity}
//     </div>
//     <span className='w-25 p-1 ms-3 h-auto d-flex flex-row align-items-center justify-content-center '>
//       {item.target}
//     </span>
//     <div className='w-25 p-1 ms-3 h-auto d-flex justify-content-center align-items-center'>
//       {item.host}
//     </div>
//     <div className='w-25 p-1 ms-3 h-auto d-flex justify-content-center align-items-center'>
//       <ConvertDate date={item.timestamp} />
//     </div>
//   </div>
// </div>
