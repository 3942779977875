import {FC, useEffect, useState, ReactNode} from 'react'
import {useSelector} from 'react-redux'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {RootState} from '../../../../setup/redux/Store'
import useGetUserData from '../../../../Utils/useGetUserData'
import {useNavigate} from 'react-router-dom'

type Props = {children: ReactNode}

const AuthInit: FC<Props> = (props) => {
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const getUserData = useGetUserData()
  const user = useSelector((state: RootState) => state.root.user)
  const navigate = useNavigate()
  useEffect(() => {
    if (user) {
      setShowSplashScreen(false)
    } else {
      getUserData().then((e) => {
        setShowSplashScreen(false)
        if (false
          // e.is_user_new
        ) {
          navigate('/services')
          console.log('user', e.is_user_new)
        }
      })
    }

    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
}

export default AuthInit


