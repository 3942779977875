import React from 'react'
import DatePicker from 'react-multi-date-picker'
import {KTSVG} from '../../../../_metronic/helpers'

import {useFormik} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import { useIntl } from 'react-intl'

var numberRegex = /^\d+$/
const fleetTest = [
  'wait for API 1',
  'wait for API 2',
  'wait for API 3',
  'wait for API 4',
  'wait for API 5',
]




const initialValues = {
  email: '',
  password: '',
  fleet: 'wait for API 5',
  time: 'once',
  schedule: '2024/2/2',
  interval: '1',
}
export default function ModalSchedule () {
  const intl = useIntl()
  const validate = (values: any) => {
    const errors: any = {}
    if (!values.interval) {
      errors.interval = intl.formatMessage({id: 'Required'})
    } else if (!numberRegex.test(values.interval)) {
      errors.interval = intl.formatMessage({id: 'Invalid interval'})
    }
    return errors
  }
  const loginSchema = Yup.object().shape({
    target: Yup.string()
      .min(3, intl.formatMessage({id: 'Minimum 3 symbols'}))
      .max(50, intl.formatMessage({id: 'Maximum 50 symbols'}))
      .required(intl.formatMessage({id: 'Target is required'})),
    fleet: Yup.string().required(intl.formatMessage({id: 'Fleet is required'})),
    time: Yup.string().required(intl.formatMessage({id: 'Time is required'})),
  })
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    validate,
    onSubmit: values => {
      alert(JSON.stringify(values, null, 2));
    },
  })
  return (
    <>
      <div className='modal fade' id='kt_modal_Schedule' style={{backdropFilter: 'blur(3px)'}}>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h3 className='modal-title'>{intl.formatMessage({id: 'Schedule'})}</h3>

              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2 image_change_theme2' />
              </div>
            </div>

            <div className='modal-body'>
              <form
                className='w-100 form'
                onSubmit={formik.handleSubmit}
                noValidate
                id='kt_login_signin_form'
              >
                <div className='fv-row mb-10'>
                  <label className='form-label fs-6 fw-bolder  '>{intl.formatMessage({id: 'fleet'})}</label>
                  <select
                    className={`form-select form-select-solid ${clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.fleet && formik.errors.fleet},
                      {
                        'is-valid': formik.touched.fleet && !formik.errors.fleet,
                      }
                    )}`}
                    data-kt-select2='true'
                    data-placeholder='Severity Filter'
                    data-allow-clear='true'

                    {...formik.getFieldProps('fleet')}
                    name='fleet'
                  >
                    {fleetTest?.map((item, ind) => (
                      <option key={ind} value={ind}>
                        {item}
                      </option>
                    ))}
                  </select>
                  {formik.touched.fleet && formik.errors.fleet && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.fleet}</span>
                    </div>
                  )}
                </div>
                <div className='fv-row mb-10'>
                  <label className='form-label fs-6 fw-bolder  '>{intl.formatMessage({id: 'Time'})}</label>
                  <select
                    className={`form-select form-select-solid ${clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.time && formik.errors.time},
                      {
                        'is-valid': formik.touched.time && !formik.errors.time,
                      }
                    )}`}
                    data-kt-select2='true'
                    data-placeholder='Severity Filter'
                    data-allow-clear='true'
                    {...formik.getFieldProps('time')}
                    name='time'
                  >
                    <option value='once'>{intl.formatMessage({id: 'Once'})}</option>
                    <option value='daily'>{intl.formatMessage({id: 'Daily'})}</option>
                    <option value='weekly'>{intl.formatMessage({id: 'Weekly'})}</option>
                  </select>
                  {formik.touched.time && formik.errors.time && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.time}</span>
                    </div>
                  )}
                </div>
                <div className='fv-row mb-10'>
                  <label className='form-label fs-6 fw-bolder  '>{intl.formatMessage({id: 'Interval'})}</label>
                  <input
                    className={` ${clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.interval && formik.errors.interval},
                      {
                        'is-valid': formik.touched.interval && !formik.errors.interval,
                      }
                    )}`}
                    placeholder='interval'
                    {...formik.getFieldProps('interval')}
                    name='interval'
                  />
                  {formik.touched.interval && formik.errors.interval && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.interval}</span>
                    </div>
                  )}
                </div>
                <div className='fv-row mb-10'>
                  <label className='form-label fs-6 fw-bolder   d-block'>{intl.formatMessage({id: 'Schedule'})}</label>
                  <DatePicker
                    className='bg-dark'
                    render={
                      <input
                        placeholder={intl.formatMessage({id: 'Schedule'})}
                        {...formik.getFieldProps('schedule')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {'is-invalid': formik.touched.schedule && formik.errors.schedule},
                          {
                            'is-valid': formik.touched.schedule && !formik.errors.schedule,
                          }
                        )}
                        type='text'
                        name='schedule'
                        autoComplete='off'
                      />
                    }
                    onChange={(e) => {
                      console.log('e')
                    }}
                  />

                  {formik.touched.time && formik.errors.time && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.time}</span>
                    </div>
                  )}
                </div>
              </form>
            </div>

            <div className='modal-footer'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                {intl.formatMessage({id: 'Close'})}
              </button>
              <button type='button' className='btn btn-primary'>
                {intl.formatMessage({id: 'Save'})}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
