import axios from 'axios'
import useErrorHandling from './useErrorHandling'
import { useDispatch } from 'react-redux'
import { FilterOptionAssets } from '../app/modules/auth'

export default function useGetFilterOptionsDomains() {
    const errorHandling = useErrorHandling()
    const dispatch = useDispatch()
    const getFilterOptionsDomains = async () => {
        try {
            const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/assets/filter/`)
            errorHandling(data.status) //logout when invalid token && redirect 404
            if (data.status >= 200 && data.status < 300) {
              dispatch(FilterOptionAssets(data.data))
      
            } else {
              console.error(
                `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/assets/filter/ \n`,
                data
              )
            }
          } catch (e) {
            console.log(e)
          }
    }
    return getFilterOptionsDomains
}
