import React, {useState, useEffect} from 'react'
import axios from 'axios'

import TargetTable from './components/TargetTable'
import PageLoading from '../loading/PageLoading'
import ReactPaginate from 'react-paginate'
import useErrorHandling from '../../../Utils/useErrorHandling'
import {useDispatch, useSelector} from 'react-redux'
import { RootState } from '../../../setup/redux/Store'
import { SetDataScope } from '../../modules/auth'
import { useIntl } from 'react-intl'
import EmptyPage from '../emptyPage/EmptyPage'
export const Target = () => {
  const [loading, setLoading] = useState(false)
  const targetData = useSelector((state: RootState) => state?.root?.data?.dataScope)
  const dispatch = useDispatch()
  const errorHandling = useErrorHandling()
  const intl = useIntl()
  const handlePageClick = (event: {selected: number}) => {
    fetchTargetData(event.selected + 1)
  }
  const fetchTargetData = async (page = 1) => {
    setLoading(true)
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/targets/?page=${page}`)
      errorHandling(data.status) //logout when invalid token && redirect 404
      if (data.status >= 200 && data.status < 300) {
        dispatch(SetDataScope(data.data))
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/targets/?page=${page} \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }
  useEffect(() => {
    const nowDate = new Date().getTime()
    if (targetData?.expireTime && targetData.expireTime >= nowDate) {
    } else {
      fetchTargetData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      {loading ? (
        <PageLoading />
      ) : (targetData?.data&&targetData?.data?.results.length) ? (
        <>
          <div className='row d-flex flex-row mt-5 '>
            <TargetTable scopeData={targetData?.data?.results} />
          </div>
          <div className='d-flex justify-content-end bg-body pb-4 pe-4 rounded-bottom'>
            <ReactPaginate
              nextLabel={`${intl.formatMessage({id: 'Next'})} >`}
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={targetData?.data?.total_pages}
              forcePage={targetData?.data?.current_page - 1}
              previousLabel={`< ${intl.formatMessage({id: 'Previous'})}`}
              pageClassName='page-item'
              pageLinkClassName='page-link'
              previousClassName='page-item'
              previousLinkClassName='page-link'
              nextClassName='page-item'
              nextLinkClassName='page-link'
              breakLabel='...'
              breakClassName='page-item'
              breakLinkClassName='page-link'
              containerClassName='pagination'
              activeClassName='active'
              renderOnZeroPageCount={null}
            />
          </div>
        </>
      ) : <EmptyPage/>}
    </>
  )
}
