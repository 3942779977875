import React from 'react'
import './Loading.css'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
const PageLoading = () => {
  return (
    <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
      <span className='indicator-progress' style={{display: 'block'}}>
        Please wait...
        <span className='spinner-border spinner-lg spinner-border-lg align-middle ms-2'></span>
      </span>
    </div>
    // <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
    // <div className='loader_ring'>
    //   {' '}
    //   <img alt='Logo' src={toAbsoluteUrl('/tabLogo.png')} className='p-5 w-100 h-100' />
    //   <span className='loader_span'></span>
    // Loading...
    // </div>
    // </div>
  )
}

export default PageLoading
