import { httpStatusCode_tag } from "../../../modules/auth/redux/DEMO_DATA";


export default function OverviewTag() {
  return (
    <div className='h-25 d-flex gap-5 flex-wrap'>
    {httpStatusCode_tag.map((item, ind) => (
      <div key={ind} className='d-flex align-items-center gap-2'>
        <div
          className='w-20px h-5px rounded-pill'
          style={{backgroundColor: item.color}}
        ></div>
        <span className=' text-capitalize'>{item.title}</span>
      </div>
    ))}
  </div>
  )
}
