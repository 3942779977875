import React, {useState, useEffect, useLayoutEffect} from 'react'
import axios from 'axios'
import Section from './components/Section'
import {KTSVG} from '../../../_metronic/helpers'
import useErrorHandling from '../../../Utils/useErrorHandling'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../setup/redux/Store'
import {SetDataSmtp} from '../../modules/auth'
import {useIntl} from 'react-intl'
import {SMTP_DATA} from '../../modules/auth/redux/DEMO_DATA'

export const SMTP = () => {
  const [loading, setLoading] = useState(false)
  const [initialIndex, setInitialIndex] = useState(0)
  const [isOpen, setIsOpen] = useState<null | number>(null)
  const errorHandling = useErrorHandling()
  const smtpData = useSelector((state: RootState) => state?.root?.data?.dataSmtp)
  const user = useSelector((state: RootState) => state?.root?.user)
  const dispatch = useDispatch()
  const intl = useIntl()
  const fetchSmtpIdList = async () => {
    setLoading(true)
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/smtp/`)
      errorHandling(data.status) //logout when invalid token && redirect 404
      if (data.status >= 200 && data.status < 300) {
        dispatch(SetDataSmtp(data.data))
      } else {
        console.error(`error in get data from ${process.env.REACT_APP_API_ENDPOINT}/smtp \n`, data)
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }
  useLayoutEffect(() => {
    const nowDate = new Date().getTime()
    if (smtpData?.expireTime && smtpData.expireTime >= nowDate) {
    } else {
      fetchSmtpIdList()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <div className={`row d-flex flex-row mt-5 ${loading && 'skeleton'}`}>
        <div className='w-100 h-100'>
          <div
            className={` h-100  `}
            style={!smtpData.data?.length && !user?.is_user_scan ? {filter: 'blur(3px)'} : {}}
          >
            <div className='card card-body w-100 p-3 d-flex h-50px flex-row justify-content-between align-items-center  mb-5'>
              <button
                className={`col-auto border border-0 text-white bg-body px-4 m-0`}
                onClick={() => {
                  if (smtpData?.data?.length && initialIndex > 0) {
                    setInitialIndex(initialIndex - 1)
                  }
                }}
              >
                <KTSVG
                  svgClassName='image_change_theme2'
                  path='/media/icons/duotune/arrows/arr002.svg'
                  className='svg-icon svg-icon-2x ms-2 svg-icon-white '
                />
              </button>

              <h3 className='  text-center m-0'>
                {(!smtpData?.data?.length && !user?.is_user_scan
                  ? SMTP_DATA
                  : smtpData?.data || [])[initialIndex]?.data.host &&
                  `${intl.formatMessage({id: 'SMTP Inspect for'})} ${
                    (!smtpData?.data?.length && !user?.is_user_scan
                      ? SMTP_DATA
                      : smtpData?.data || [])[initialIndex]?.data.host
                  }`}
              </h3>

              <button
                className={` col-auto border border-0 text-white bg-body px-4 m-0`}
                onClick={() => {
                  if (
                    smtpData?.data?.length &&
                    initialIndex <
                      (!smtpData?.data?.length && !user?.is_user_scan
                        ? SMTP_DATA
                        : smtpData?.data || []
                      ).length -
                        1
                  ) {
                    setInitialIndex(initialIndex + 1)
                  }
                }}
              >
                <KTSVG
                  svgClassName='image_change_theme2'
                  path='/media/icons/duotune/arrows/arr001.svg'
                  className='svg-icon svg-icon-2x ms-2 svg-icon-white '
                />
              </button>
            </div>
            <div className='d-flex wrap gap-5'></div>
            {Object.keys(
              (!smtpData?.data?.length && !user?.is_user_scan ? SMTP_DATA : smtpData?.data || [])[
                initialIndex
              ]?.data || {}
            )
              .filter(
                (item) =>
                  item !== 'host' &&
                  item !== 'score' &&
                  item !== 'timestamp' &&
                  item !== 'smtp_address'
              )
              ?.map(
                (item) =>
                  (!smtpData?.data?.length && !user?.is_user_scan
                    ? SMTP_DATA
                    : smtpData?.data || [])[initialIndex]?.data[item]
              )
              ?.map((section, ind) => (
                <Section
                  key={section?.title+ind}
                  data={section}
                  ind={ind}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                />
              ))}
          </div>
        </div>
      </div>
    </>
  )
}
