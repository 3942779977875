import React from 'react'
import {Badge, Button} from 'react-bootstrap'
import ConvertDate from '../../../Utils/ConvertDate'
import {useIntl} from 'react-intl'
const data = [
  {
    id: 5742,
    date: '2024-11-12T11:05:55.280850Z',
    expire: '2024-11-12T11:05:55.280850Z',
    total: 120000,
    status: 'pending',
  },
  {
    id: 5742,
    date: '2024-11-12T11:05:55.280850Z',
    expire: '2024-11-12T11:05:55.280850Z',
    total: 120000,
    status: 'pending',
  },
]
export default function History () {
  const intl = useIntl()
  return (
    <div dir='rtl' className='w-100 h-100'>
      <div className=' card w-100 '>
        <h1 className='p-5 pb-0 directions-rtl'> فهرست پرداخت </h1>
        <hr />
        <div className='p-5'>
          <div className='d-flex gap-10 justify-content-between mb-5 px-5'>
            <div className='w-100 mw-300px'>
              <input
                type='text'
                className='form-control w-100  form-control-solid text-gray-700'
                placeholder={intl.formatMessage({id: 'Search'})}
              />
            </div>
            <div className='w-100 mw-300px'>
              <div>
                <select
                  className='form-select form-select-solid'
                  data-kt-select2='true'
                  data-placeholder='Severity Filter'
                  data-allow-clear='true'
                >
                  <option value='all'>همه</option>
                </select>
              </div>
            </div>
          </div>
          <table className='table '>
            <thead>
              <tr className='bg-gray-300'>
                <th scope='col' className=' rounded-end-pill text-center fs-5'>
                  <strong> # فاکتور</strong>
                </th>
                <th scope='col' className='text-center fs-5'>
                  <strong>تاریخ فاکتور</strong>
                </th>
                <th scope='col' className='text-center fs-5'>
                  <strong>تاریخ سررسید</strong>
                </th>
                <th scope='col' className='text-center fs-5'>
                  <strong>مجموع کل</strong>
                </th>
                <th scope='col' className='text-center fs-5'>
                  {' '}
                  <strong>وضعیت</strong>
                </th>
                <th scope='col' className='rounded-0 rounded-start-pill text-center fs-5'>
                  {' '}
                  <strong>عملیات</strong>{' '}
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item.id} className='border-bottom border-bottom-1'>
                  <th scope='row' className='text-center '>
                    <div className=' h-auto d-flex justify-content-center p-1 align-items-center min-h-35px'>
                      {' '}
                      <strong>{item.id}</strong>
                    </div>
                  </th>
                  <td className='text-center '>
                    {' '}
                    <div className=' h-auto d-flex justify-content-center p-1 align-items-center h-100'>
                      <ConvertDate date={item?.date} />
                    </div>
                  </td>
                  <td className='text-center '>
                    {' '}
                    <div className=' h-auto d-flex justify-content-center p-1 align-items-center min-h-35px'>
                      <ConvertDate date={item?.expire} />
                    </div>
                  </td>
                  <td className='text-center '>
                    <div className=' h-auto d-flex justify-content-center p-1 align-items-center min-h-35px'>
                      {' '}
                      {item.total}/﷼
                    </div>
                  </td>
                  <td className='text-center '>
                    <div className=' h-auto d-flex justify-content-center p-1 align-items-center min-h-35px'>
                      <strong
                        className={`border ${
                          item.status === 'pending'
                            ? 'border-warning bg-warning'
                            : item.status === 'success'
                            ? 'border-success bg-success'
                            : 'border-danger bg-danger'
                        }  rounded bg-opacity-25 p-1 px-3`}
                      >
                        {item.status === 'pending'
                          ? 'درحال پردازش'
                          : item.status === 'success'
                          ? 'موفق'
                          : 'ناموفق'}
                      </strong>
                    </div>
                  </td>
                  <td className='d-flex gap-2 justify-content-center'>
                    <Button size='sm' variant='outline'>
                      جزئیات فاکتور
                    </Button>
                    <Button size='sm'>پیش نمایش</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
