import { useIntl } from 'react-intl'
interface Data {
  id: string
  start_time: string
}
export default function HistoryModal ({data}: {data: Data[]}) {
  const intl = useIntl()
  return (
    <div
      className='modal fade'
      id='historyModal'
      tabIndex={-1}
      aria-labelledby='historyModalLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-lg '>
        <div className='modal-content'>
          <div className='modal-header'>
            <h2>
              <strong>{data?.length} {intl.formatMessage({id: 'Vulnerable Software'})}</strong>
            </h2>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
            />
          </div>
          <div className='d-flex flex-wrap gap-10 p-5 min-h-400px'>
            {data?.map((item, ind) => {
              const date = new Date(item.start_time)
              return (
                <div
                  className=' px-3 py-3 border border-2 rounded date_box'
                  key={ind}
                  style={{height: 'fit-content'}}
                >
                  <span style={{fontSize: 10, color: '#ffffff82'}}>
                    {date?.toString()?.split(' ')[1]} {date?.toString()?.split(' ')[2]}
                  </span>
                  <div className='d-flex justify-content-center align-items-center bg-success bg-gradient py-2 rounded'>
                    <span>{33}</span>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
