/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {memo, useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'
interface ChartData {
  x: string[]
  y: {
    name: string
    data: number[]
  }[]
}
type Props = {
  chartData: ChartData
}

const HeatmapChart: React.FC<Props> = memo(({chartData}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  var options: ApexOptions = {
    series: [
      {
        name: 'Jan',
        data: [5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,],
      },
      {
        name: 'Feb',
        data: [15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,],
      },
      {
        name: 'Mar',
        data: [25,25,25,25,25,25,25,25,25,25,25,25,25,25,25,25,25,25,25,],
      },
      {
        name: 'Apr',
        data: [35,35,35,35,35,35,35,35,35,35,35,35,35,35,35,35,35,35,35,],
      },
      {
        name: 'May',
        data: [45,45,45,45,45,45,45,45,45,45,45,45,45,45,45,45,45,45,45,],
      },
      {
        name: 'Jun',
        data: [55,55,55,55,55,55,55,55,55,55,55,55,55,55,55,55,55,55,55,],
      },
      {
        name: 'Jul',
        data: [65,65,65,65,65,65,65,65,65,65,65,65,65,65,65,65,65,65,65,],
      },
      {
        name: 'Aug',
        data: [75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,75,],
      },
      {
        name: 'Sep',
        data: [85,85,85,85,85,85,85,85,85,85,85,85,85,85,85,85,85,85,85,],
      },
    ],
    chart: {
      height: '100%',
      type: 'heatmap',
      toolbar:{
        show:false
      },
      zoom:{
        enabled:false
      }
    },
    stroke:{
      colors:['#000000']},
    dataLabels: {
      enabled: false
    },


    plotOptions: {
      heatmap: {
        // useFillColorAsStroke: true,
        radius: 0,
        enableShades: false,
        colorScale: {
          ranges: [{
              from: 0,
              to: 10,
              color: '#000297'
            },
            {
              from: 10,
              to: 20,
              color: '#0d50cf'
            },
            {
              from: 20,
              to: 30,
              color: '#6599f5'
            },
            {
              from: 30,
              to: 40,
              color: '#29cd0f'
            },
            {
              from: 40,
              to: 50,
              color: '#b3cd0f'
            },
            {
              from: 50,
              to: 60,
              color: '#ddc310',
        
            },
            {
              from: 60,
              to: 70,
              color: '#e39d0f'
            },
            {
              from: 70,
              to: 80,
              color: '#ff0000'
            },
            {
              from: 80,
              to: 90,
              color: '#ac101e'
            },
          ],
        },
    
      }
    },
  }
  useEffect(() => {
    if (!chartRef.current) {
      return
    }
    const chart = new ApexCharts(chartRef.current, options)
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, chartData])

  return (
    <div
      ref={chartRef}
      id='kt_charts_widget_8_chart'
      style={{height: '100%'}}
      // className='p-0'
    />
  )
})

export {HeatmapChart}


