import React from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import './main.css'

const DatePicker = () => {
  return (
    <FullCalendar
      plugins={[dayGridPlugin]}
      initialView='dayGridMonth'
      events={[
        {title: 'event 1', date: '2022-11-11'},
        {title: 'event 2', date: '2022-11-20'},
      ]}
      eventClick={function(info) {
        // dateClick={function(info) {}}
        // info.el.style.borderColor = 'red';
      }}
      // eventContent={(e) => {
      //   return (
      //     <>
      //       <b>{e.timeText}</b>
      //       <i>{e.event.title}</i>
      //     </>
      //   )
      // }}
    />
  )
}

export default DatePicker

// import React from 'react'
// import FullCalendar from '@fullcalendar/react' // must go before plugins
// import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
// import './main.css'

// const DatePicker = () => {
//   return (
//     <FullCalendar
//       plugins={[dayGridPlugin]}
//       initialView='dayGridMonth'
//       events={[
//         {title: 'event 1', date: '2022-11-11'},
//         {title: 'event 2', date: '2022-11-20'},
//       ]}
//       dateClick={function(info) {console.log(info)}}
//       eventClick={function(info) {
//         console.log(info);
//         // info.el.style.borderColor = 'red';
//       }}
//       // eventContent={(e) => {
//       //   console.log(e);
//       //   return (
//       //     <>
//       //       <b>{e.timeText}</b>
//       //       <i>{e.event.title}</i>
//       //     </>
//       //   )
//       // }}
//     />
//   )
// }

// export default DatePicker
