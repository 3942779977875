import {useCallback, useState} from 'react'
import {Position, Node, Connection} from 'react-flow-renderer'
import {Edge} from 'react-flow-renderer'
import ReactFlow, {
  addEdge,
  Controls,
  MarkerType,
  useNodesState,
  useEdgesState,
} from 'react-flow-renderer'
import CustomEdge from './CustomEdge'
import './WorkFlow.scss'
import {KTSVG} from '../../../../_metronic/helpers'
import ModalSchedule from './ModalSchedule'
import {useSearchParams} from 'react-router-dom'
import CustomNode from './CustomNode'
import {useFormik} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
// import {useNavigate} from 'react-router-dom'
import DrawerDags from './DrawerDags'
import {useIntl} from 'react-intl'
import {
  WORKFLOW_INITIAL_EDGES as initialEdges,
  WORKFLOW_INITIAL_NODES as initialNodes,
  WORKFLOW_ASSET_DISCOVERY_NODES,
  WORKFLOW_ASSET_DISCOVERY_EDGES,
  WORKFLOW_ACTIVE_SCAN_NODES,
  WORKFLOW_ACTIVE_SCAN_EDGES,
} from '../../../modules/auth/redux/DEMO_DATA'
function selectDAG (id: string | null) {
  switch (id) {
    case 'active_scan':
      return {initialNodes: WORKFLOW_ACTIVE_SCAN_NODES, initialEdges: WORKFLOW_ACTIVE_SCAN_EDGES}
    case 'asset_discovery':
      return {
        initialNodes: WORKFLOW_ASSET_DISCOVERY_NODES,
        initialEdges: WORKFLOW_ASSET_DISCOVERY_EDGES,
      }

    default:
      return {initialNodes: initialNodes, initialEdges: initialEdges}
  }
}

interface CustomEdge extends Edge {
  markerEnd?: string // Define markerEnd as an optional string
}
interface DragHandlerProps {
  id: string
  label: string
  className: string
  type: string
}

const initialValues = {
  name: '',
  description: '',
}

const edgeTypes = {
  custom: CustomEdge,
}
const nodeTypes = {
  custom: CustomNode,
}

const DAG = () => {
  const [searchParams] = useSearchParams()
  const {initialNodes, initialEdges} = selectDAG(searchParams.get('id'))
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes)
  const [edges, setEdges, onEdgesChange] = useEdgesState(
    initialEdges?.map((item) => {
      item.markerEnd = MarkerType.Arrow
      item.style = {cursor: 'pointer'}
      return item
    })
  )
  const intl = useIntl()
  const loginSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, intl.formatMessage({id: 'Minimum 3 symbols'}))
      .max(50, intl.formatMessage({id: 'Maximum 50 symbols'}))
      .required(intl.formatMessage({id: 'Name is required'})),
    description: Yup.string().required(intl.formatMessage({id: 'Description is required'})),
  })
  const onConnect = useCallback(
    (params: Connection) =>
      setEdges((eds) =>
        addEdge({...params, markerEnd: {type: MarkerType.Arrow}, style: {cursor: 'pointer'}}, eds)
      ),
    []
  )
  function addNode (item: Node) {
    const copy = [...nodes]
    copy.push(item)
    setNodes(copy)
  }
  function dragHandler (item: DragHandlerProps) {
    setSelectedNodeItem(item)
  }
  function dropHandler (e: React.DragEvent<HTMLDivElement>) {
    const element = e.target as HTMLElement
    const rect = element.getBoundingClientRect()
    console.log('ddd', rect)
    if (selectedNodeItem) {
      const item: Node = {
        ...selectedNodeItem,
        data: {label: <div className='label'>{selectedNodeItem.label}</div>},
        position: {
          x: e.clientX - rect.left,
          y: e.clientY - rect.top,
        },
        id: Math.random()?.toString(),
      }
      if (selectedNodeItem.type === 'input') {
        item.sourcePosition = Position.Right
      } else if (selectedNodeItem.type === 'output') {
        item.targetPosition = Position.Left
      } else {
        item.targetPosition = Position.Left
        item.sourcePosition = Position.Right
      }
      addNode(item)
    }
  }

  const [show, setShow] = useState(true)
  const [selectedNodeItem, setSelectedNodeItem] = useState<DragHandlerProps | undefined>()
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2))
    },
  })
  return (
    <>
      {/* {start drawer} */}
      <DrawerDags dragHandler={dragHandler} />
      {/* {end drawer} */}

      {/* {start toolkit} */}
      <div className='workflow_toolkit'>
        <div className='tooltip_tt'>
          <KTSVG path={'/media/play.svg'} className={'svg-icon-1 svg-icon-success'} />
          <span className='tooltiptext_tt'>{intl.formatMessage({id: 'Execute'})}</span>
        </div>
        <div data-bs-toggle='modal' data-bs-target='#kt_modal_Schedule' className='tooltip_tt'>
          <KTSVG path={'/media/time.svg'} className={'svg-icon-1 svg-icon-primary'} />
          <span className='tooltiptext_tt'>{intl.formatMessage({id: 'Schedule'})}</span>
        </div>
        <div className='tooltip_tt'>
          <KTSVG path={'/media/save.svg'} className={'svg-icon-1 svg-icon-warning'} />
          <span className='tooltiptext_tt'>{intl.formatMessage({id: 'Save'})}</span>
        </div>
        <div
          className='tooltip_tt'
          onClick={() => {
            setEdges(initialEdges)
            setNodes(initialNodes)
          }}
        >
          <KTSVG path={'/media/reset.svg'} className={'svg-icon-1 svg-icon-danger'} />
          <span className='tooltiptext_tt'>{intl.formatMessage({id: 'Reset'})}</span>
        </div>
        <div className='tooltip_tt' data-bs-toggle='modal' data-bs-target='#kt_modal_edit'>
          <KTSVG path={'/media/edit.svg'} className={'svg-icon-1 svg-icon-info'} />
          <span className='tooltiptext_tt'>{intl.formatMessage({id: 'Edit'})}</span>
        </div>
      </div>
      {/* {end toolkit} */}
      <h2 className='position-absolute top-100px'>Name : {` ${searchParams.get('id')}`}</h2>
      {/* {start workflow} */}
      <div style={{width: '100%', height: '100% ', direction: 'ltr'}}>
        <ReactFlow
          onDragOver={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          onDrop={(e) => {
            e.preventDefault()
            e.stopPropagation()
            console.log(e)
            dropHandler(e)
          }}
          nodeTypes={nodeTypes}
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          // onNodesDelete={}
          onConnect={onConnect}
          snapToGrid={true}
          edgeTypes={edgeTypes}
          fitView
          // attributionPosition="top-right"
        >
          {/* <Background />*/}
          <Controls />
        </ReactFlow>
      </div>
      {/* {end workflow} */}
      <ModalSchedule />
      {/* {edit Workflow} */}
      <>
        <div className='modal fade' id='kt_modal_edit' style={{backdropFilter: 'blur(3px)'}}>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h3 className='modal-title'>{intl.formatMessage({id: 'Edit'})}</h3>

                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr061.svg'
                    className='svg-icon-2 image_change_theme2'
                  />
                </div>
              </div>

              <div className='modal-body'>
                <form
                  className='w-100 form'
                  onSubmit={formik.handleSubmit}
                  noValidate
                  id='kt_login_signin_form'
                >
                  <div className='fv-row mb-10'>
                    <label className='form-label fs-6 fw-bolder  '>
                      {intl.formatMessage({id: 'Name'})}
                    </label>
                    <input
                      type='text'
                      className={`form-control w-100  form-control-solid text-gray-700 ${clsx(
                        'form-control form-control-lg form-control-solid',
                        {'is-invalid': formik.touched.name && formik.errors.name},
                        {
                          'is-valid': formik.touched.name && !formik.errors.name,
                        }
                      )}`}
                      placeholder={intl.formatMessage({id: 'Name'})}
                      {...formik.getFieldProps('name')}
                      name='name'
                    />

                    {formik.touched.name && formik.errors.name && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.name}</span>
                      </div>
                    )}
                  </div>
                  <div className='fv-row mb-10'>
                    <label className='form-label fs-6 fw-bolder  '>
                      {intl.formatMessage({id: 'Description'})}
                    </label>
                    <textarea
                      className={`form-control w-100  form-control-solid text-gray-700 ${clsx(
                        'form-control form-control-lg form-control-solid',
                        {'is-invalid': formik.touched.description && formik.errors.description},
                        {
                          'is-valid': formik.touched.description && !formik.errors.description,
                        }
                      )}`}
                      placeholder={intl.formatMessage({id: 'Description'})}
                      {...formik.getFieldProps('description')}
                      name='description'
                    />

                    {formik.touched.description && formik.errors.description && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.description}</span>
                      </div>
                    )}
                  </div>
                </form>
              </div>

              <div className='modal-footer'>
                <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                  {intl.formatMessage({id: 'Close'})}
                </button>
                <button
                  type='button'
                  className='btn btn-primary'
                  data-bs-dismiss='modal'
                  onClick={() => {
                    formik.resetForm()
                  }}
                >
                  {intl.formatMessage({id: 'Save'})}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  )
}

export default DAG
