import React, {useState, useEffect} from 'react'
import axios from 'axios'
import PageLoading from '../loading/PageLoading'
import ReactPaginate from 'react-paginate'
import useErrorHandling from '../../../Utils/useErrorHandling'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import Arr001 from '../components/Arr001'
import {KTSVG} from '../../../_metronic/helpers'
import Sort from '../images/Sort'
import ConvertDate from '../../../Utils/ConvertDate'
import {RootState} from '../../../setup/redux/Store'
import {SetDataAsset} from '../../modules/auth'
import {useIntl} from 'react-intl'
import EmptyPage from '../emptyPage/EmptyPage'

const colorConvertor = (grade: string) => {
  let color = ''
  color = grade === 'A' ? '#02694a' : color
  color = grade === 'B' ? '#255a8f' : color
  color = grade === 'C' ? '#9c8705' : color
  color = grade === 'D' ? '#75041e' : color
  color = grade === 'E' ? '#421919' : color
  color = grade === 'F' ? '#565672' : color

  return color
}
interface Results {
  id: number
  score: string
  host: string
  total_vulnerabilities: number
  vulnerabilities: {
    low: number
    high: number
    info: number
    medium: number
    critical: number
  }
  timestamp: string
  timestamp_jalali: string
}
export const Asset = () => {
  const [loading, setLoading] = useState(false)
  const assetData = useSelector((state: RootState) => state?.root?.data?.dataAsset)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const errorHandling = useErrorHandling()
  const [sortState, setSortState] = useState<string>('')
  const intl = useIntl()
  const fetchAssetData = async (page = 1, sort = '') => {
    setLoading(true)
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/assets/?page=${page}${sort && '&ordering=' + sort}`
      )
      errorHandling(data.status) //logout when invalid token && redirect 404
      if (data.status >= 200 && data.status < 300) {
        dispatch(SetDataAsset(data.data))
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/assets/?page=${page} \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }
  useEffect(() => {
    const nowDate = new Date().getTime()
    if (assetData?.expireTime && assetData.expireTime >= nowDate) {
    } else {
      fetchAssetData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handlePageClick = (event: {selected: number}) => {
    fetchAssetData(event.selected + 1, sortState)
  }
  function sort (sortItem: string) {
    if (!(process.env.REACT_APP_MODE === 'demo')) {
      setSortState(sortItem)
      fetchAssetData(assetData?.data?.current_page, sortItem)
    }
  }
  return (
    <>
      {loading ? (
        <PageLoading />
      ) : (assetData?.data && assetData?.data.results?.length) ? (
        <>
          <div className='row d-flex flex-row  mt-5 overflow-auto'>
            <div className='w-100 h-100'>
              <div className={`card card-body h-100 bg-body rounded-bottom-0`}>
                {/* start title */}
                <div className='w-100 pe-3 d-flex h-100px flex-row justify-content-between align-items-start pt-2'>
                  <h3 className=' '>{intl.formatMessage({id: 'Assets'})}</h3>
                  <input
                    type='text'
                    className='form-control w-100 mw-300px form-control-solid text-gray-700'
                    placeholder={intl.formatMessage({id: 'Search'})}
                  />
                </div>
                {/* start body */}
                {/* start titles */}
                <div className='w-100 m-0 pe-3 '>
                  <div className='w-100 d-flex fs-5 fw-bold bg-gray-400 rounded-1 mb-4 p-2 pt-1 pb-1 justify-content-between align-items-center'>
                    <div className='w-80px p-1 h-auto' style={{minWidth: 80}}>
                      <div
                        className='w-100  h-auto d-flex justify-content-start align-items-center pointer'
                        onClick={() => {
                          if (sortState === '-score') {
                            sort('score')
                          } else {
                            sort('-score')
                          }
                        }}
                      >
                        <span>{intl.formatMessage({id: 'Grade'})}</span>{' '}
                        <Sort
                          className='svg-icon svg-icon-1x ms-2 svg-icon-white '
                          style={{rotate: sortState === '-score' ? '180deg' : '0deg'}}
                        />
                      </div>
                    </div>
                    <div className='w-100 p-1 ps-2 ms-5 h-auto'>
                      <div
                        className='w-100  h-auto d-flex justify-content-start align-items-center pointer'
                        onClick={() => {
                          if (sortState === '-domain') {
                            sort('domain')
                          } else {
                            sort('-domain')
                          }
                        }}
                      >
                        <span>{intl.formatMessage({id: 'Name'})}</span>
                        <Sort
                          className='svg-icon svg-icon-1x ms-2 svg-icon-white '
                          style={{rotate: sortState === '-domain' ? '180deg' : '0deg'}}
                        />
                      </div>
                    </div>
                    <div className='w-75 p-1 h-auto d-flex justify-content-center'>
                      <div
                        className='w-100  h-auto d-flex justify-content-center align-items-center pointer'
                        onClick={() => {
                          if (sortState === '-total_vulnerabilities') {
                            sort('total_vulnerabilities')
                          } else {
                            sort('-total_vulnerabilities')
                          }
                        }}
                      >
                        {' '}
                        <span>{intl.formatMessage({id: 'Total Vulnerabilities'})}</span>{' '}
                        <Sort
                          className='svg-icon svg-icon-1x ms-2 svg-icon-white '
                          style={{
                            rotate: sortState === '-total_vulnerabilities' ? '180deg' : '0deg',
                          }}
                        />
                      </div>
                    </div>
                    <div className='w-100 d-flex justify-content-center p-1 ms-2 h-auto'>
                      {intl.formatMessage({id: 'Vulnerabilities'})}
                    </div>
                    <div className='w-100 p-1 ms-2 h-auto d-flex justify-content-center'>
                      <div
                        className='w-100 h-auto d-flex justify-content-center align-items-center pointer'
                        onClick={() => {
                          if (sortState === '-timestamp') {
                            sort('timestamp')
                          } else {
                            sort('-timestamp')
                          }
                        }}
                      >
                        {' '}
                        <span>{intl.formatMessage({id: 'Last Seen'})}</span>{' '}
                        <Sort
                          className='svg-icon svg-icon-1x ms-2 svg-icon-white '
                          style={{rotate: sortState === '-timestamp' ? '180deg' : '0deg'}}
                        />
                      </div>
                    </div>
                    <div className='w-50 p-1 ms-2 h-auto d-flex justify-content-center pe-3'>
                      {' '}
                      {intl.formatMessage({id: 'View'})}{' '}
                    </div>
                  </div>
                </div>
                {/* end titles */}
                <div className={`w-100 h-100 overflow-visible  pe-3 m-0 pb-8`}>
                  {assetData?.data?.results &&
                    assetData?.data?.results?.map((item: Results, index: number) => {
                      return (
                        <div
                          key={item.id}
                          className={`w-100 m-0 p-0 h-auto bg-gray-200 rounded-1 d-flex justify-content-center align-items-center ${
                            index === assetData?.data?.results?.length - 1 ? null : 'mb-3'
                          }`}
                        >
                          <div className='w-100 d-flex p-2 pt-1 pb-1 justify-content-between align-items-center'>
                            <div
                              className='w-80px d-flex  align-items-center p-1 h-auto'
                              style={{minWidth: 80}}
                            >
                              <div
                                className={`d-flex justify-content-center align-items-center position-relative`}
                              >
                                <KTSVG
                                  path='/media/hexagonal.svg'
                                  className='svg-icon svg-icon-3x ms-2 svg-icon-body'
                                  fill={colorConvertor(item.score)}
                                />
                                <span className='position-absolute ms-2 text-black fw-bolder'>
                                  {item.score}
                                </span>
                              </div>
                            </div>
                            <div className='w-100 p-1 ps-2 ms-5 h-auto'> {item.host} </div>
                            <div className='w-75 p-1 h-auto d-flex justify-content-center'>
                              {item.total_vulnerabilities}
                            </div>
                            <div className='w-100 d-flex justify-content-center align-items-center p-1 h-auto'>
                              {/* critical */}
                              <div className='w-100 h-auto d-flex justify-content-center align-items-center '>
                                <div className='w-15px h-15px me-2 rounded-3 border-icritical border border-2 tooltip_tt'>
                                  <span className='tooltiptext_tt'>
                                    {intl.formatMessage({id: 'critical'})}
                                  </span>
                                </div>
                                {item.vulnerabilities.critical}
                              </div>
                              {/* high */}
                              <div className='w-100 h-auto d-flex justify-content-center align-items-center ms-2 '>
                                <div className='w-15px h-15px me-2 rounded-3 border-ihigh border border-2 tooltip_tt'>
                                  <span className='tooltiptext_tt'>
                                    {intl.formatMessage({id: 'high'})}
                                  </span>
                                </div>
                                {item.vulnerabilities.high}
                              </div>
                              {/* medium */}
                              <div className='w-100 h-auto d-flex justify-content-center align-items-center ms-2 '>
                                <div className='w-15px h-15px me-2 rounded-3 border-imedium border border-2 tooltip_tt'>
                                  <span className='tooltiptext_tt'>
                                    {intl.formatMessage({id: 'medium'})}
                                  </span>
                                </div>
                                {item.vulnerabilities.medium}
                              </div>
                              {/* low */}
                              <div className='w-100 h-auto d-flex justify-content-center align-items-center ms-2 '>
                                <div className='w-15px h-15px me-2 rounded-3 border-ilow border border-2 tooltip_tt'>
                                  <span className='tooltiptext_tt'>
                                    {intl.formatMessage({id: 'low'})}
                                  </span>
                                </div>
                                {item.vulnerabilities.low}
                              </div>
                              {/* info */}
                              <div className='w-100 h-auto d-flex justify-content-center align-items-center ms-2 '>
                                <div className='w-15px h-15px me-2 rounded-3 border-iinfo border border-2  tooltip_tt'>
                                  <span className='tooltiptext_tt'>
                                    {intl.formatMessage({id: 'info'})}
                                  </span>
                                </div>
                                {item.vulnerabilities.info}
                              </div>
                            </div>
                            <div className='w-100 p-1 ms-2 h-auto d-flex justify-content-center'>
                              <ConvertDate date={item.timestamp} jalali={item.timestamp_jalali}/>
                            </div>
                            <div className='w-50 p-1 ms-2 h-auto d-flex justify-content-center pe-3'>
                              <button
                                className={`col-auto border border-0  bg-gray-200 p-0 m-0`}
                                onClick={() => {
                                  navigate({
                                    pathname: '/asset-details',
                                    search: `?id=${item.id}`,
                                  })
                                }}
                              >
                                <Arr001 fill={'#fff'} className={'image_change_theme'} />
                              </button>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </div>

                <div className={`row d-flex flex-row position-absolute bottom-0 my-2`}>
                  <div
                    className={`w-auto h-auto  ms-2 me-2 px-3 d-flex align-items-center text-uppercase rounded-pill bg-body border border-1 border-gray-800`}
                  >
                    <div className='w-15px h-15px me-2 rounded-3 border-iinfo border border-2' />
                    {intl.formatMessage({id: 'info'})}
                  </div>
                  <div
                    className={`w-auto h-auto  ms-2 px-3 d-flex align-items-center text-uppercase rounded-pill bg-body border border-1 border-gray-800`}
                  >
                    <div className='w-15px h-15px me-2 rounded-3 border-ilow border border-2' />
                    {intl.formatMessage({id: 'low'})}
                  </div>
                  <div
                    className={`w-auto h-auto  ms-2 px-3 d-flex align-items-center text-uppercase rounded-pill bg-body border border-1 border-gray-800`}
                  >
                    <div className='w-15px h-15px me-2 rounded-3 border-imedium border border-2' />
                    {intl.formatMessage({id: 'medium'})}
                  </div>
                  <div
                    className={`w-auto h-auto  ms-2 px-3 d-flex align-items-center text-uppercase rounded-pill bg-body border border-1 border-gray-800`}
                  >
                    <div className='w-15px h-15px me-2 rounded-3 border-ihigh border border-2' />
                    {intl.formatMessage({id: 'high'})}
                  </div>
                  <div
                    className={`w-auto h-auto  ms-2 px-3 d-flex align-items-center text-uppercase rounded-pill bg-body border border-1 border-gray-800`}
                  >
                    <div className='w-15px h-15px me-2 rounded-3 border-icritical border border-2' />
                    {intl.formatMessage({id: 'critical'})}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-end bg-body pb-4 pe-4 rounded-bottom'>
            <ReactPaginate
              nextLabel={`${intl.formatMessage({id: 'Next'})} >`}
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={assetData?.data?.total_pages}
              forcePage={assetData?.data?.current_page - 1}
              previousLabel={`< ${intl.formatMessage({id: 'Previous'})}`}
              pageClassName='page-item'
              pageLinkClassName='page-link'
              previousClassName='page-item'
              previousLinkClassName='page-link'
              nextClassName='page-item'
              nextLinkClassName='page-link'
              breakLabel='...'
              breakClassName='page-item'
              breakLinkClassName='page-link'
              containerClassName='pagination'
              activeClassName='active'
              renderOnZeroPageCount={null}
            />
          </div>
        </>
      ) : <EmptyPage/>}
    </>
  )
}
