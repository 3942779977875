import React, {memo} from 'react'
import './toltip.scss'
interface Data {
  date: string
  percentage: number | null
}
function choseColor (percentage: number | null): string {
  if (percentage) {
    switch (true) {
      case percentage > 0 && percentage <= 25:
        return 'red'
      case percentage > 25 && percentage <= 50:
        return 'orange'
      case percentage > 50 && percentage <= 75:
        return 'yellow'
      case percentage > 75:
        return 'green'
      default:
        return 'gray'
    }
  }
  return 'gray'
}
const Timeline = memo(function ({data}: {data: Data[]}) {
  return (
    <>
      <div className='w-100 h-40px d-flex justify-content-between'>
        {data?.map((item, index) => (
          <div
            className='h-100  rounded-pill tooltip_tt'
            key={index}
            style={{backgroundColor: '#fff', width: `calc(100% / ${data.length * 2})`}}
          >
            <div
              className='h-100 w-100 timeline_hover rounded-pill '
              style={{
                backgroundColor: choseColor(item.percentage),
              }}
            >
            </div>
              <div className='tooltip_text_tt '>
                <span>{new Date(item.date).toLocaleDateString()}</span>
                <p>{item.percentage ? item.percentage + '%' : 'No data'}</p>
              </div>
          </div>
        ))}
      </div>
    </>
  )
})
export default Timeline
