/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {memo, useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
interface ChartData {
  x: string[]
  y: {
    info: number[]
    low: number[]
    medium: number[]
    high: number[]
    critical: number[]
  }
}
type Props = {
  className: string
  severityValue?: any
  chartData: ChartData
}

const ChartsWidget8: React.FC<Props> = memo(({className, severityValue, chartData}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!chartRef.current) {
      return
    }
    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(height, chartData, severityValue)
    )
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, severityValue, chartData])

  return (
    <div
      ref={chartRef}
      id='kt_charts_widget_8_chart'
      style={{height: '300px'}}
      // className='p-0'
    />
  )
})

export {ChartsWidget8}

function getChartOptions (height: number, chartData: ChartData, isData?: any): ApexOptions {
  const labelColor = '#474761'

  const strokeColor = '#000'

  const color1 = getCSSVariableValue('--bs-primary')

  const color2 = getCSSVariableValue('--bs-success')

  const color3 = getCSSVariableValue('--bs-primary')

  return {
    series: [
      {
        name: 'Info',
        data: isData?.info
          ? chartData.y.info.length === 1
            ? [0, ...chartData.y.info]
            : chartData.y.info
          : [],
      },
      {
        name: 'Low',
        data: isData?.low
          ? chartData.y.low.length === 1
            ? [0, ...chartData.y.low]
            : chartData.y.low
          : [],
      },
      {
        name: 'Medium',
        data: isData?.medium
          ? chartData.y.medium.length === 1
            ? [0, ...chartData.y.medium]
            : chartData.y.medium
          : [],
      },
      {
        name: 'High',
        data: isData?.high
          ? chartData.y.high.length === 1
            ? [0, ...chartData.y.high]
            : chartData.y.high
          : [],
      },
      {
        name: 'Critical',
        data: isData?.critical
          ? chartData.y.critical.length === 1
            ? [0, ...chartData.y.critical]
            : chartData.y.critical
          : [],
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      width: '100%',
      height: height,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 2,
      //colors: ['#0BB783', '#3699FF', '#F8C851', '#DD4B50','#91243E'],
      colors: ['#02694a', '#255a8f', '#9c8705', '#c02407', '#75041e'],
    },
    xaxis: {
      offsetX: 0,
      offsetY: 0,
      categories: chartData.x.length === 1 ? ['start', ...chartData.x] : chartData.x,
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        // show: false,
        position: 'front',
        stroke: {
          color: strokeColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      theme: 'dark',
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val + ' issues'
        },
      },
    },
    colors: ['#02694a20', '#255a8f30', '#9c870540', '#c0240720', '#75041e60'],
    grid: {
      yaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    markers: {
      colors: [color1, color2, color3],
      strokeColors: [color1, color2, color3],
      strokeWidth: 0,
    },
  }
}
