import React, {memo, useState} from 'react'
import {useFormik} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import DatePicker from 'react-multi-date-picker'
import {KTSVG} from '../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import TimePicker from 'react-multi-date-picker/plugins/time_picker'
import axios from 'axios'
import Trash from '../../images/Trash'
import {useIntl} from 'react-intl'
import useErrorHandling from '../../../../Utils/useErrorHandling'
import persian from 'react-date-object/calendars/persian'
import persian_fa from 'react-date-object/locales/persian_fa'
import gregorian from 'react-date-object/calendars/gregorian'
import gregorian_en from 'react-date-object/locales/gregorian_en'
import {useLang} from '../../../../_metronic/i18n/Metronici18n'
const timezoneRegex = /([A-Z]+[\+-][0-9]+)/
const DomainRegex = /^(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/
interface DAGS {
  id: string | number
  description: undefined | string | null
  name?: string | null | undefined
}
const initialValues = {
  target_domain: '',
  description: '',
  workflow_id: '',
  time: '',
  start_time: '',
}

function getTimezone () {
  const timezone = new Date()?.toString()
  const test: any[] | null = timezone.match(timezoneRegex)
  if (test && test[0]) return test[0]
}
interface Props {
  dags: DAGS[] | any
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  getData: (page?: number) => Promise<void>
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  setErrShow: React.Dispatch<React.SetStateAction<string>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}
const CreateModal = memo(
  ({dags, getData, setShow, setErrShow, setLoading, showModal, setShowModal}: Props) => {
    const [page, setPage] = useState(1)
    const [workflowId, setWorkflowId] = useState<null | number | string>(null)
    const [start, setStart] = useState<any>(null)
    const [target, setTarget] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [time, setTime] = useState('')
    const intl = useIntl()
    const lang = useLang()?.lang.selectedLang
    const errorHandling = useErrorHandling()
    const loginSchema = Yup.object().shape({
      target_domain: Yup.string()
        .min(3, intl.formatMessage({id: 'Minimum 3 symbols'}))
        .max(50, intl.formatMessage({id: 'Maximum 50 symbols'}))
        .required(intl.formatMessage({id: 'Target is required'}))
        .matches(DomainRegex, intl.formatMessage({id: 'Domain is not valid'})),
    })
    function clearForm () {
      formik.resetForm()
      setWorkflowId(null)
      setStart(null)
      setPage(1)
      setTarget('')
      setDescription('')
      setTime('')
      setLoading(false)
      setShowModal(false)
    }
    const formik = useFormik({
      initialValues,
      validationSchema: loginSchema,
      onSubmit: (values) => {
        setLoading(true)
        console.log('gggggggg',start?.unix )
        axios
          .post(`${process.env.REACT_APP_API_ENDPOINT}/scan/`, {
            ...values,
            start_time: start?.unix ? new Date(start.unix * 1000).toISOString() : null,
          })
          .then((res) => {
            errorHandling(res.status)
            if (res.status >= 200 && res.status < 300) {
              setShow(true)
              getData()
            } else {
              setErrShow(
                res?.data?.detail || intl.formatMessage({id: 'Sorry, an error has occurred'})
              )
            }
            clearForm()
          })
          .catch((e) => {
            console.log('message \n', e?.response?.data?.error)
            clearForm()
            setErrShow(e.massage || intl.formatMessage({id: 'Sorry, an error has occurred'}))
            setLoading(false)
          })
      },
    })

    return (
      <>
        <div
          className={`modal fade ${showModal ? 'show' : ''}`}
          style={{backdropFilter: 'blur(3px)', display: `${showModal ? 'block' : 'none'}`}}
        >
          <div className='modal-dialog' style={{minWidth: '50%'}}>
            <div className='modal-content'>
              <div className='modal-header'>
                <h3 className='modal-title'> {intl.formatMessage({id: 'Create New Scan'})}</h3>

                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                  onClick={clearForm}
                >
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr061.svg'
                    className='svg-icon-2 image_change_theme2'
                  />
                </div>
              </div>

              <div className='modal-body'>
                <form
                  className='w-100 form'
                  onSubmit={formik.handleSubmit}
                  noValidate
                  id='kt_login_signin_form'
                >
                  {page === 1 && (
                    <>
                      <div className='fv-row mb-10'>
                        <label className='form-label fs-6 fw-bolder  '>
                          {intl.formatMessage({id: 'Target'})}{' '}
                        </label>
                        <input
                          placeholder='example.com'
                          {...formik.getFieldProps('target_domain')}
                          className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {
                              'is-invalid':
                                formik.touched.target_domain && formik.errors.target_domain,
                            },
                            {
                              'is-valid':
                                formik.touched.target_domain && !formik.errors.target_domain,
                            }
                          )}
                          type='text'
                          name='target_domain'
                          autoComplete='off'
                          value={target}
                          onChange={(e) => {
                            setTarget(e.target.value)
                            formik.setFieldValue('target_domain', e.target.value)
                          }}
                        />
                        {formik.touched.target_domain && formik.errors.target_domain && (
                          <div className='fv-plugins-message-container mt-2 text-danger'>
                            <span role='alert'>{formik.errors.target_domain}</span>
                          </div>
                        )}
                      </div>
                      <div className='fv-row mb-10'>
                        <label className='form-label fs-6 fw-bolder'>
                          {intl.formatMessage({id: 'Description'})}
                        </label>
                        <textarea
                          placeholder={intl.formatMessage({id: 'Description'})}
                          {...formik.getFieldProps('description')}
                          className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {
                              'is-invalid': formik.touched.description && formik.errors.description,
                            },
                            {
                              'is-valid': formik.touched.description && !formik.errors.description,
                            }
                          )}
                          name='description'
                          autoComplete='off'
                          value={description}
                          onChange={(e) => {
                            setDescription(e.target.value)
                            formik.setFieldValue('description', e.target.value)
                          }}
                        />
                        {formik.touched.description && formik.errors.description && (
                          <div className='fv-plugins-message-container mt-2 text-danger'>
                            <span role='alert'>{formik.errors.description}</span>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  {page === 2 && (
                    <>
                      <>
                        <Link to={'/workFlow'}>
                          <button
                            type='button'
                            className='btn btn-info mb-5'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                          >
                            {intl.formatMessage({id: 'Create workflow'})}
                          </button>
                        </Link>
                      </>
                      <div style={{maxHeight: '60vh', overflow: 'auto'}}>
                        {dags?.map((item: DAGS | any, ind: number) => (
                          <div
                            key={ind}
                            onClick={() => {
                              setWorkflowId(item.id)
                              formik.setFieldValue('workflow_id', item.id)
                            }}
                            className='card w-100 p-4 bg-gray-400 hover mt-4'
                            style={workflowId === item.id ? {border: '1px solid #3cf03c'} : {}}
                          >
                            <div className='d-flex justify-content-between text-capitalize'>
                              <h2>{item.description}</h2>
                              <div
                                className='d-flex gap-3'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                              >
                                <Link to={'/workFlow'}>
                                  <KTSVG
                                    path='/media/visibel.svg'
                                    className='svg-icon svg-icon-2x ms-2 svg-icon-white'
                                  />
                                </Link>
                              </div>
                            </div>
                            <p>
                              {item.description === 'passive scan'
                                ? 'Enumerate all subdomains and check vulnerabilities using passive scan'
                                : item.description === 'active scan'
                                ? 'Enumerate all subdomains and check vulnerabilities using active scan. this workflow could take a while'
                                : 'Enumerate all subdomains and asset'}
                            </p>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                  {page === 3 && (
                    <>
                      <h3 style={{marginBottom: 30}}>{getTimezone()}</h3>
                      <div className='fv-row mb-10'>
                        <label className='form-label fs-6 fw-bolder'>
                          {intl.formatMessage({id: 'Rate'})}
                        </label>
                        <select
                          className={`form-select form-select-solid ${clsx(
                            'form-control form-control-lg form-control-solid',
                            {'is-invalid': formik.touched.time && formik.errors.time},
                            {
                              'is-valid': formik.touched.time && !formik.errors.time,
                            }
                          )}`}
                          data-kt-select2='true'
                          data-placeholder='Severity Filter'
                          data-allow-clear='true'
                          {...formik.getFieldProps('time')}
                          name='time'
                          onChange={(e) => {
                            formik.setFieldValue('time', e.target.value)
                            setTime(e.target.value)
                          }}
                          value={time}
                        >
                          <option></option>
                          <option value='once'>Once</option>
                          <option value='daily'>Daily</option>
                          <option value='weekly'>Weekly</option>
                          <option value='monthly'>Monthly </option>
                        </select>
                        {formik.touched.time && formik.errors.time && (
                          <div className='fv-plugins-message-container mt-2 text-danger'>
                            <span role='alert'>{formik.errors.time}</span>
                          </div>
                        )}
                      </div>

                      <div className='fv-row mb-10'>
                        <label className='form-label fs-6 fw-bolder d-block'>
                          {' '}
                          {intl.formatMessage({id: 'Schedule'})}
                        </label>
                        <div className='d-flex'>
                          <DatePicker
                            format='YYYY/MM/DD HH:mm'
                            className='bg-dark'
                            value={start}
                            plugins={[<TimePicker position='bottom' />]}
                            render={
                              <input
                                placeholder={intl.formatMessage({id: 'Schedule'})}
                                readOnly
                                {...formik.getFieldProps('start_time')}
                                className={clsx('form-control form-control-lg form-control-solid')}
                                type='text'
                                name='start_time'
                                autoComplete='off'
                              />
                            }
                            onChange={(e) => {
                              formik.setFieldValue('start_time', e)
                              setStart(e)
                            }}
                            calendar={lang === 'fa' ? persian : gregorian}
                            locale={lang === 'fa' ? persian_fa : gregorian_en}
                          />
                          <button
                            className={`col-auto border border-0 text-white p-0 m-0`}
                            style={{backgroundColor: 'transparent'}}
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              formik.setFieldValue('start_time', null)
                              setStart(null)
                            }}
                          >
                            <Trash className='svg-icon-2x svg-icon-danger' />
                          </button>
                        </div>
                        {formik.touched.start_time && formik.errors.start_time && (
                          <div className='fv-plugins-message-container mt-2 text-danger '>
                            <span role='alert'>{formik.errors.start_time}</span>
                          </div>
                        )}
                      </div>
                      <div className='d-flex gap-5 align-items-center'>
                        <button
                          type='submit'
                          id='kt_sign_in_submit'
                          className='btn btn-success'
                          disabled={
                            formik.isSubmitting ||
                            !formik.isValid ||
                            !start ||
                            process.env.REACT_APP_MODE === 'demo'
                          }
                          data-bs-dismiss='modal'
                          aria-label='Close'
                        >
                          <span
                            className='indicator-label'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                          >
                            {intl.formatMessage({id: 'Save'})}
                          </span>
                        </button>
                        <button
                          type='submit'
                          id='kt_sign_in_submit'
                          className='btn btn-success'
                          disabled={
                            formik.isSubmitting ||
                            !formik.isValid ||
                            !!!time ||
                            !!start ||
                            process.env.REACT_APP_MODE === 'demo'
                          }
                          data-bs-dismiss='modal'
                          aria-label='Close'
                        >
                          <span
                            className='indicator-label'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                          >
                            {intl.formatMessage({id: 'Start Instantly'})}
                          </span>
                        </button>
                      </div>
                      {process.env.REACT_APP_MODE === 'demo' && (
                        <span className='text-danger '>
                          You are currently using the demo version and this feature is not available
                          to you
                        </span>
                      )}
                    </>
                  )}
                </form>
              </div>

              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-info'
                  onClick={() => {
                    setPage(page - 1)
                  }}
                  style={page <= 1 ? {display: 'none'} : {display: 'flex'}}
                >
                  {intl.formatMessage({id: 'Back'})}
                </button>
                <button
                  type='button'
                  className='btn btn-info'
                  onClick={() => {
                    setPage(page + 1)
                  }}
                  style={page >= 3 ? {display: 'none'} : {display: 'flex'}}
                  disabled={
                    (page === 2 && !workflowId) || (page === 1 && !DomainRegex.test(target))
                  }
                >
                  {intl.formatMessage({id: 'Next'})}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
)
export default CreateModal
