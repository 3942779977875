/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import axios from 'axios'
import {Dispatch} from 'react'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../_metronic/helpers'

const initialValues = {
  email: '',
  password: '',
  confirmPassword: '',
}
interface fetchLoginValueProps {
  email: string
  password: string
  confirmPassword: string
}
/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function SignUp () {
  const [loading, setLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [pass, setPass] = useState(false)
  const [confirmPass, setConfirmPass] = useState(false)
  const [password, setPassword] = useState('')
  const intl = useIntl()
  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({id: 'Wrong email format'}))
      .min(3, intl.formatMessage({id: 'Minimum 3 symbols'}))
      .max(50, intl.formatMessage({id: 'Maximum 50 symbols'}))
      .required(intl.formatMessage({id: 'Email is required'})),
    password: Yup.string()
      .min(10, intl.formatMessage({id: 'Minimum 10 symbols'}))
      .required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), ''], 'Passwords must match')
      .required('Confirm Password is required'),
  })
  const fetchSignUp = async (
    values: fetchLoginValueProps,
    setStatus: Dispatch<any>,
    setSubmitting: Dispatch<any>
  ) => {
    setStatus('')
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/auth/users/`,
        {
          email: values.email,
          password: values.password,
        },
        {withCredentials: false}
      )
      if (data?.status >= 200 && data.status < 300) {
        setStatus('You have successfully registered,\n please confirm your email')
        setIsError(false)
      } else {
        console.error(`error in login \n`, data)
        setStatus('The si detail is incorrect')
        setIsError(true)
      }
      setLoading(false)
    } catch (e: any) {
      console.log('aaa', e)
      setLoading(false)
      setSubmitting(false)
      setStatus(e?.message || 'The sign up detail is incorrect')
      setIsError(true)
    } finally {
      setLoading(false)
    }
  }
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (
      values: fetchLoginValueProps,
      {setStatus, setSubmitting}: {setStatus: Dispatch<any>; setSubmitting: Dispatch<any>}
    ) => {
      setLoading(true)
      fetchSignUp(values, setStatus, setSubmitting)
    },
  })

  return (
    // <form
    //   className='w-100 form'
    //   onSubmit={formik.handleSubmit}
    //   noValidate
    //   id='kt_login_signup_form'
    // >
    //   {formik.status ? (
    //     <div
    //       className={`mb-lg-15 alert ${isError ? 'alert-danger' : 'alert-success'}  rounded-pill`}
    //     >
    //       <div className='font-weight-bold alert-text'>{formik.status}</div>
    //     </div>
    //   ) : (
    //     <div className='d-flex justify-content-center flex-column align-items-center mb-10 p-8 rounded'>
    //       <h1>Sign up To Panel</h1>
    //       <span className='opacity-75'>Enter your details to Sign up </span>
    //     </div>
    //   )}

    //   <div className='mb-10 fv-row'>
    //     <input
    //       placeholder='Email'
    //       {...formik.getFieldProps('email')}
    //       className={clsx(
    //         'form-control h-auto text-white placeholder-white opacity-70 bg-dark-o-70 rounded-pill border-0 py-4 px-8 mb-5',
    //         {'is-invalid': formik.touched.email && formik.errors.email},
    //         {
    //           'is-valid': formik.touched.email && !formik.errors.email,
    //         }
    //       )}
    //       type='email'
    //       name='email'
    //       autoComplete='off'
    //       style={{opacity: 0.7, backgroundColor: 'rgba(24, 28, 50, 0.28)'}}
    //     />
    //     {formik.touched.email && formik.errors.email && (
    //       <div className='fv-plugins-message-container text-center text-danger'>
    //         <span role='alert'>{formik.errors.email}</span>
    //       </div>
    //     )}
    //   </div>

    //   <div className='mb-10 fv-row position-relative'>
    //     <input
    //       placeholder='Password'
    //       autoComplete='off'
    //       {...formik.getFieldProps('password')}
    //       onChange={(e) => {
    //         setPassword(e.target.value)
    //         formik.setFieldValue('password', e.target.value)
    //       }}
    //       value={password}
    //       className={clsx(
    //         'form-control h-auto text-white placeholder-white opacity-70 bg-dark-o-70 rounded-pill border-0 py-4 px-8 mb-5 '
    //       )}
    //       style={{opacity: 0.7, backgroundColor: 'rgba(24, 28, 50, 0.28)'}}
    //       type={pass ? 'text' : 'password'}
    //     />
    //     <span
    //       className='btn btn-sm btn-icon position-absolute translate-middle  end-0 me-n2'
    //       style={{top: 25}}
    //       data-kt-password-meter-control='visibility'
    //       onClick={() => {
    //         setPass((perv) => !perv)
    //       }}
    //     >
    //       {!pass ? (
    //         <KTIcon
    //           iconName={'eye-slash'}
    //           className='fs-1  text-white'
    //           style={{cursor: 'pointer'}}
    //         />
    //       ) : (
    //         <KTIcon iconName={'eye'} className='fs-1 text-white' style={{cursor: 'pointer'}} />
    //       )}
    //     </span>
    //     {formik.touched.password && formik.errors.password && (
    //       <div className='fv-plugins-message-container text-center text-danger'>
    //         <span role='alert'>{formik.errors.password}</span>
    //       </div>
    //     )}
    //   </div>

    //   <div className='mb-10 fv-row position-relative'>
    //     <input
    //       type={confirmPass ? 'text' : 'password'}
    //       placeholder='Confirm password'
    //       autoComplete='off'
    //       {...formik.getFieldProps('confirmPassword')}
    //       className={clsx(
    //         'form-control h-auto text-white placeholder-white opacity-70 bg-dark-o-70 rounded-pill border-0 py-4 px-8 mb-5 '
    //       )}
    //       style={{opacity: 0.7, backgroundColor: 'rgba(24, 28, 50, 0.28)'}}
    //     />
    //     <span
    //       className='btn btn-sm btn-icon position-absolute translate-middle  end-0 me-n2'
    //       style={{top: 25}}
    //       data-kt-password-meter-control='visibility'
    //       onClick={() => {
    //         setConfirmPass((perv) => !perv)
    //       }}
    //     >
    //       {!confirmPass ? (
    //         <KTIcon
    //           iconName={'eye-slash'}
    //           className='fs-1  text-white'
    //           style={{cursor: 'pointer'}}
    //         />
    //       ) : (
    //         <KTIcon iconName={'eye'} className='fs-1 text-white' style={{cursor: 'pointer'}} />
    //       )}
    //     </span>
    //     {formik.touched.confirmPassword && formik.errors.confirmPassword && (
    //       <div className='fv-plugins-message-container  text-center text-danger'>
    //         <div className='fv-help-block'>
    //           <span role='alert'>{formik.errors.confirmPassword}</span>
    //         </div>
    //       </div>
    //     )}
    //   </div>

    //   <div className='form-group d-flex flex-wrap justify-content-between align-items-center px-8'>
    //     <Link to='/auth/login' id='kt_login_forgot' className='text-white font-weight-bold'>
    //       I have an account{' '}
    //     </Link>
    //   </div>

    //   <div className='text-center mt-10'>
    //     <button
    //       type='submit'
    //       id='kt_sign_in_submit'
    //       disabled={loading || !formik.isValid}
    //       className='btn btn-pill border border-white rounded-pill btn-outline-white font-weight-bold  px-15 py-3'
    //       style={{opacity: 0.9}}
    //     >
    //       {!loading && <span className='indicator-label'>Sign up</span>}
    //       {loading && (
    //         <span className='indicator-progress' style={{display: 'block'}}>
    //           Please wait...
    //           <span className='spinner-border-sm spinner-border align-middle ms-2'></span>
    //         </span>
    //       )}
    //     </button>

    //   </div>
    // </form>
    <div className='card'>
      <div className='card-body py-5 px-md-5'>
        <div className='d-flex justify-content-center flex-column align-items-center mb-10 p-8 rounded'>
          <h1>Sign up To Panel</h1>
          {formik.status ? (
            <div
              className={`font-weight-bold alert-text ${
                isError ? 'alert-danger' : 'alert-success'
              } `}
            >
              {formik.status}
            </div>
          ) : (
            <span className='opacity-75'>Enter your details to Sign up </span>
          )}
        </div>
        <form onSubmit={formik.handleSubmit} noValidate id='kt_login_signup_form'>
          <div data-mdb-input-init className='form-outline mb-4'>
            <input
              type='email'
              id='form3Example3'
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
              placeholder='www.example.com'
              {...formik.getFieldProps('email')}
            />

            <div className='d-flex align-items-center justify-content-between py-1'>
              <label className='form-label m-0' htmlFor='form3Example3'>
                Email address
              </label>

              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container text-center text-danger'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              )}
            </div>
          </div>

          <div data-mdb-input-init className='form-outline mb-4 position-relative'>
            <input
              type={pass ? 'text' : 'password'}
              id='form3Example4'
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.password && formik.errors.password},
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
              placeholder=' '
              {...formik.getFieldProps('password')}
              onChange={(e) => {
                setPassword(e.target.value)
                formik.setFieldValue('password', e.target.value)
              }}
            />
            <span
              className='btn btn-sm btn-icon position-absolute translate-middle   me-n2'
              style={{top: 22, right: 13}}
              data-kt-password-meter-control='visibility'
              onClick={() => {
                setPass((perv) => !perv)
              }}
            >
              {!pass ? (
                <KTIcon
                  iconName={'eye-slash'}
                  className='fs-1  text-white'
                  style={{cursor: 'pointer'}}
                />
              ) : (
                <KTIcon iconName={'eye'} className='fs-1 text-white' style={{cursor: 'pointer'}} />
              )}
            </span>
            <div className='d-flex align-items-center justify-content-between py-1'>
              <label className='form-label m-0' htmlFor='form3Example4'>
                New password
              </label>
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container text-center text-danger'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              )}
            </div>
          </div>

          <div data-mdb-input-init className='form-outline mb-4 position-relative'>
            <input
              type={confirmPass ? 'text' : 'password'}
              id='form3Example4'
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword},
                {
                  'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
                }
              )}
              placeholder=' '
              {...formik.getFieldProps('confirmPassword')}
            />
            <span
              className='btn btn-sm btn-icon position-absolute translate-middle   me-n2'
              style={{top: 22, right: 13}}
              data-kt-password-meter-control='visibility'
              onClick={() => {
                setConfirmPass((perv) => !perv)
              }}
            >
              {!confirmPass ? (
                <KTIcon
                  iconName={'eye-slash'}
                  className='fs-1  text-white'
                  style={{cursor: 'pointer'}}
                />
              ) : (
                <KTIcon iconName={'eye'} className='fs-1 text-white' style={{cursor: 'pointer'}} />
              )}
            </span>
            <div className='d-flex align-items-center justify-content-between py-1'>
              <label className='form-label m-0' htmlFor='form3Example4'>
                Confirm password
              </label>
              {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                <div className='fv-plugins-message-container text-center text-danger'>
                  <span role='alert'>{formik.errors.confirmPassword}</span>
                </div>
              )}
            </div>
          </div>
          <button
            className='btn btn-lg btn-block btn-primary w-100'
            type='submit'
            id='kt_sign_in_submit'
            disabled={loading || !formik.isValid}
            style={{opacity: 0.9}}
          >
            {!loading && <span className='indicator-label'>Sign up</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border-sm spinner-border align-middle ms-2'></span>
              </span>
            )}
          </button>
        </form>
        <hr className='w-100' />
        <div className='form-group d-flex flex-wrap justify-content-between align-items-center px-8 mt-5'>
          <Link to='/auth' id='kt_login_forgot' className='text-white font-weight-bold'>
            Cancel
          </Link>
        </div>
      </div>
    </div>
  )
}
