import React, {useEffect, useState} from 'react'
import {MapContainer, TileLayer, Marker, useMap} from 'react-leaflet'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
const customIcon = L.icon({
  iconUrl: '/ip.png', // Path to your custom icon
  iconSize: [32, 32], // Size of the icon
  iconAnchor: [16, 32], // Anchor point for the icon
})
const UpdateMapCenter = ({position}: any) => {
  const map = useMap()
  useEffect(() => {
    map.setView(position)
  }, [position, map])

  return null
}
export default function Map ({lat, lng}: {lat: number; lng: number}) {
  const [position, setPosition] = useState<any>(null)

  useEffect(() => {
    if (lat && lng) {
      setPosition({lat, lng})
    }
  }, [lat, lng])
  return position ? (
    <>
      <MapContainer
        center={position}
        zoom={13}
        scrollWheelZoom={false}
        style={{width: '100%', height: 300, borderRadius: '20px'}}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />
        <Marker icon={customIcon} position={position}></Marker>
        <UpdateMapCenter position={position} />
      </MapContainer>
    </>
  ) : (
    <></>
  )
}
