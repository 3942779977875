import React from 'react'
import './Card.scss'
import {useNavigate} from 'react-router-dom'
export default function CardProvider ({link, children,loading}: {link: string ,children:React.ReactNode,loading: boolean}) {
  const navigate = useNavigate()
 
  return (
    <div
      className={`card_custom bg-secondary card ${loading&&'skeleton'}`}
      onClick={() => {
        navigate(link)
      }}
      style={{height:'fit-content', width:'100%'}}
    >
      {children}
    </div>
  )
}
