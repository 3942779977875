/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {FormikErrors, useFormik} from 'formik'
import axios from 'axios'
import {Dispatch} from 'react'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../_metronic/helpers'

const initialValues = {
  new_password: '',
  confirmPassword: '',
}
interface fetchLoginValueProps {
  new_password: string
  confirmPassword: string
}

export function SetNewPassword () {
  const [loading, setLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [pass, setPass] = useState(false)
  const [confirmPass, setConfirmPass] = useState(false)
  const [new_password, setNew_password] = useState('')
  const params = useParams()
  const {uid, token} = params
  const navigate = useNavigate()
  const intl = useIntl()
  const loginSchema = Yup.object().shape({
    new_password: Yup.string()
      .min(10, intl.formatMessage({id: 'Minimum 10 symbols'}))
      .required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('new_password'), ''], 'Passwords must match')
      .required('Confirm Password is required'),
  })
  const fetchSetNewPassword = async (
    values: fetchLoginValueProps,
    setStatus: Dispatch<any>,
    setSubmitting: Dispatch<any>,
    setErrors: (errors: FormikErrors<fetchLoginValueProps>) => void
  ) => {
    setStatus('')
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/auth/users/reset_password_confirm/`,
        {
          new_password: values.new_password,
          uid,
          token,
        },
        {withCredentials: false}
      )
      if (data?.status >= 200 && data.status < 300) {
        setStatus('Your new_password has been successfully changed')
        setIsError(false)
        setTimeout(() => {
          navigate('/auth/login')
        }, 10000)
      } else {
        setStatus('The si detail is incorrect')
        setIsError(true)
      }
      setLoading(false)
    } catch (e: any) {
      setErrors(e.response.data)

      setLoading(false)
      setSubmitting(false)
      setStatus(e?.message || 'The sign up detail is incorrect')
      setIsError(true)
    } finally {
      setLoading(false)
    }
  }
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values: fetchLoginValueProps, {setStatus, setSubmitting, setErrors}) => {
      setLoading(true)
      fetchSetNewPassword(values, setStatus, setSubmitting, setErrors)
    },
  })

  return (
    <div className='card'>
      <div className='card-body py-5 px-md-5'>
        <div className='d-flex justify-content-center flex-column align-items-center mb-10 p-8 rounded'>
          <h1>New Password</h1>
          {formik.status ? (
            <div
              className={`font-weight-bold alert-text ${
                isError ? 'alert-danger' : 'alert-success'
              } `}
            >
              {formik.status}
            </div>
          ) : (
            <span className='opacity-75'>Enter your new password </span>
          )}
        </div>
        <form noValidate id='kt_login_password_reset_form' onSubmit={formik.handleSubmit}>
          <div data-mdb-input-init className='form-outline mb-4 position-relative'>
            <input
              type={pass ? 'text' : 'password'}
              id='form3Example4'
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.new_password && formik.errors.new_password},
                {
                  'is-valid': formik.touched.new_password && !formik.errors.new_password,
                }
              )}
              placeholder=' '
              {...formik.getFieldProps('new_password')}
              onChange={(e) => {
                setNew_password(e.target.value)
                formik.setFieldValue('new_password', e.target.value)
              }}
            />
            <span
              className='btn btn-sm btn-icon position-absolute translate-middle   me-n2'
              style={{top: 22, right: 13}}
              data-kt-password-meter-control='visibility'
              onClick={() => {
                setPass((perv) => !perv)
              }}
            >
              {!pass ? (
                <KTIcon
                  iconName={'eye-slash'}
                  className='fs-1  text-white'
                  style={{cursor: 'pointer'}}
                />
              ) : (
                <KTIcon iconName={'eye'} className='fs-1 text-white' style={{cursor: 'pointer'}} />
              )}
            </span>
            <div className='d-flex align-items-center justify-content-between py-1'>
              <label className='form-label m-0' htmlFor='form3Example4'>
                New password
              </label>
              {formik.touched.new_password && formik.errors.new_password && (
                <div className='fv-plugins-message-container text-center text-danger'>
                  <span role='alert'>{formik.errors.new_password}</span>
                </div>
              )}
            </div>
          </div>

          <div data-mdb-input-init className='form-outline mb-4 position-relative'>
            <input
              type={confirmPass ? 'text' : 'password'}
              id='form3Example4'
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword},
                {
                  'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
                }
              )}
              placeholder=' '
              {...formik.getFieldProps('confirmPassword')}
            />
            <span
              className='btn btn-sm btn-icon position-absolute translate-middle   me-n2'
              style={{top: 22, right: 13}}
              data-kt-password-meter-control='visibility'
              onClick={() => {
                setConfirmPass((perv) => !perv)
              }}
            >
              {!confirmPass ? (
                <KTIcon
                  iconName={'eye-slash'}
                  className='fs-1  text-white'
                  style={{cursor: 'pointer'}}
                />
              ) : (
                <KTIcon iconName={'eye'} className='fs-1 text-white' style={{cursor: 'pointer'}} />
              )}
            </span>
            <div className='d-flex align-items-center justify-content-between py-1'>
              <label className='form-label m-0' htmlFor='form3Example4'>
                Confirm password
              </label>
              {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                <div className='fv-plugins-message-container text-center text-danger'>
                  <span role='alert'>{formik.errors.confirmPassword}</span>
                </div>
              )}
            </div>
          </div>
          <button
            className='btn btn-lg btn-block btn-primary w-100'
            type='submit'
            id='kt_sign_in_submit'
            disabled={loading || !formik.isValid}
            style={{opacity: 0.9}}
          >
            {!loading && <span className='indicator-label'>Set new password</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border-sm spinner-border align-middle ms-2'></span>
              </span>
            )}
          </button>
        </form>
        <hr className='w-100' />
        <div className='form-group d-flex flex-wrap justify-content-between align-items-center px-8 mt-5'>
          <Link to='/auth' id='kt_login_forgot' className='text-white font-weight-bold'>
            Cancel
          </Link>
        </div>
      </div>
    </div>
  )
}
