import React, { memo } from 'react'

const Warning2 = memo(({className = '', svgClassName = 'mh-50px'}:{className?:string,svgClassName?:string}) =>{
  return (
    <span className={`svg-icon ${className}`}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24px'
        height='24px'
        viewBox='0 0 24 24'
        version='1.1'
        className={svgClassName}
      >
    <defs/>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"/>
        <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>
        <rect fill="#000000" x="11" y="7" width="2" height="8" rx="1"/>
        <rect fill="#000000" x="11" y="16" width="2" height="2" rx="1"/>
    </g>
      </svg>
    </span>
  )
})
export default Warning2