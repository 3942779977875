import {Button} from 'react-bootstrap'
import Drawer from './Drawer'
import {useEffect, useState} from 'react'
import useErrorHandling from '../../../Utils/useErrorHandling'
import axios from 'axios'
import {useDispatch} from 'react-redux'
import {SetDataIntegrations} from '../../modules/auth'
import {useSelector} from 'react-redux'
import {RootState} from '../../../setup/redux/Store'
import { useIntl } from 'react-intl'
const data = [
  {
    icon: 'test.png',
    title: 'Splunk',
    description: ' Monitor Detected Vulnerabilities in Splunk ',
    label: 'Monitoring',
  },
  {
    id: null,
    icon: 'test2.webp',
    title: 'Prometheus',
    description: ' Monitor Detected Vulnerabilities in Prometheus ',
    label: 'Monitoring',
  },
]
export default function Integrations () {
  const errorHandling = useErrorHandling()
  const dispatch = useDispatch()
  const intl = useIntl()
  const dataIntegrations = useSelector((state: RootState) => state.root.data.dataIntegrations)
  const [loading, setLoading] = useState(false)
  const [id, setId] = useState('')
  const fetchData = async () => {
    setLoading(true)
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/vul-logger/`)
      errorHandling(data.status) //logout when invalid token && redirect 404
      if (data.status >= 200 && data.status < 300) {
        dispatch(SetDataIntegrations(data.data))
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/vul-logger \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }
  useEffect(() => {
    const nowDate = new Date().getTime()
    if (dataIntegrations?.expireTime && dataIntegrations.expireTime >= nowDate) {
    } else {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <div className={`w-100 d-flex flex-column gap-5 ` }>
        {data.map((item, ind) => (
          <div className={`bg-success card ps-2 w-100  ${loading&&'skeleton'}`} key={ind}>
            <div className='d-flex gap-5 flex-row align-items-center  bg-gray-300 p-2 w-100 h-100 card shadow-none border-0'>
              <img src={item.icon} className='w-75px  h-75px  ' />
              <div className='d-flex flex-column justify-content-between gap-3 w-100 '>
                <div className='d-flex gap-2 align-items-center'>
                  <strong className=' fs-1'> {item.title}</strong>
                </div>

                <span className='text-nowrap text_hide_width'>{item.description}</span>

                <div
                  className=' bg-gray-500 p-1 border border-1 rounded  '
                  style={{width: 'fit-content'}}
                >
                  {item.label}
                </div>
              </div>
              <Button
                id='kt_drawer_Integrations_toggle'
                size='sm'
                variant='success'
                onClick={() => {
                  if (item.id === null) {
                    setId('')
                  } else {
                    setId(dataIntegrations?.data[0]?.id)
                  }
                }}
              >
                <strong className='fs-6'>{intl.formatMessage({id: 'Integrations'})}</strong>
              </Button>
            </div>
          </div>
        ))}
      </div>
      <Drawer id={id} />
    </>
  )
}
