import React from 'react'
import WafPage from './components/WafPage'


export const Waf = () => {
  return (
    <>
      <div className='row d-flex flex-row  mt-5 '>
        <WafPage />
      </div>
    </>
  )
}
