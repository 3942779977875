import clsx from 'clsx'
import {FC, useContext, useEffect} from 'react'
import {toAbsoluteUrl} from '../../../helpers'
import {useLang, setLanguage, I18nContext} from '../../../i18n/Metronici18n'
import { useIntl } from 'react-intl'
interface languagesType{
  lang:  'en' |'fa',
  name: string
  flag: string
  dir: 'ltr'|'rtl',
}
const languages:languagesType[] = [
  {
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('media/flags/united-states.svg'),
    dir: 'ltr',
  },
  {
    lang: 'fa',
    name: 'فارسی',
    flag: toAbsoluteUrl('media/flags/iran.svg'),
    dir: 'rtl',
  },
]

const Languages: FC = () => {
  const lang = useLang()?.lang.selectedLang
  const intl = useIntl()
  const currentLanguage = languages.find((x) => lang && x.lang === lang)
  const setLang = useContext(I18nContext)?.setLang

  return (
    <div
      className='menu-item px-5'
      data-kt-menu-trigger='hover'
      data-kt-menu-placement='left-start'
      data-kt-menu-flip='bottom'
    >
      <a href='#' className='menu-link px-5'>
        <span className='menu-title position-relative'>
          {intl.formatMessage({id: 'Language'})}
          <span className='fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0'>
            {currentLanguage?.name}{' '}
            <img
              className='w-15px h-15px rounded-1 ms-2'
              src={currentLanguage?.flag}
              alt='metronic'
            />
          </span>
        </span>
      </a>

      <div className='menu-sub menu-sub-dropdown w-175px py-4'>
        {languages?.map((l) => (
          <div
            className='menu-item px-3'
            key={l.lang}
            onClick={() => {
              setLanguage(l.lang, l.dir)
              if(setLang)
              setLang({selectedLang: l.lang, dir: l.dir})
            }}
          >
            <a
              href='#'
              className={clsx('menu-link d-flex px-5', {active: l.lang === currentLanguage?.lang})}
            >
              <span className='symbol symbol-20px me-4'>
                <img className='rounded-1' src={l.flag} alt='metronic' />
              </span>
              {l.name}
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export {Languages}
