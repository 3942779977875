import React, {memo, useEffect, useState} from 'react'
import {useFormik} from 'formik'
import clsx from 'clsx'

import {KTSVG} from '../../../../_metronic/helpers'

import axios from 'axios'
import useErrorHandling from '../../../../Utils/useErrorHandling'
import PageLoading from '../../loading/PageLoading'
import { useIntl } from 'react-intl'
interface Value {
  id?:number,
  target: string | null,
  workflow: number | null,
  description: string | null,
  status: string | null,
  start_time: string | null,
  rate: string | null,
}
const initialValues:Value = {
  id:0,
  target: '',
  workflow: 0,
  description: '',
  status: '',
  start_time: '',
  rate: '',
}

interface Props {
  id: null | number | string
  setId: React.Dispatch<React.SetStateAction<string | number | null>>
  getData: (page?: number) => Promise<void>
  setErrShow: React.Dispatch<React.SetStateAction<string>>
}
const EditModal = memo(({id, setId, getData, setErrShow}: Props) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const errorHandling = useErrorHandling()
  const intl = useIntl()
  function clearForm () {
    formik.resetForm()
    setId(null)
    setError('')
  }
  const formik = useFormik({
    initialValues,

    onSubmit: (values) => {
      delete values.id
      axios
        .patch(`${process.env.REACT_APP_API_ENDPOINT}/scan/${id}/`, values )
        .then((res) => {
          if(res.status >=200 && res.status <300){
            getData()
          }else{
            setErrShow(res?.data?.detail || intl.formatMessage({id: 'Sorry, an error has occurred'}))
          }
          clearForm()
        })
        .catch((e) => {
          console.log('message \n', e?.response?.data?.error)
          clearForm()
          setErrShow(e.massage || intl.formatMessage({id: 'Sorry, an error has occurred'}))
          setLoading(false)
        })
    },
  })
  const fetchScanData = async () => {
    setLoading(true)
    await axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/scan/${id}/`)
      .then((res) => {
        errorHandling(res.status)
        if (res.status >= 200 && res.status < 300) {
          formik.setValues(res.data)
        } else {
          console.log(res?.data?.detail)
          setError(res.data.massage || intl.formatMessage({id: 'Sorry, an error has occurred'}))
        }
      })
      .catch((e) => {
        setError(e?.message || intl.formatMessage({id: 'Sorry, an error has occurred'}))
      })
      .finally(() => {
        setLoading(false)
      })
  }
  useEffect(() => {
    if (id !== null && process.env.REACT_APP_MODE !== 'demo') fetchScanData()
  }, [id])
  return (
    <>
      <div
        className='modal fade '
        id='kt_modal_edit'
        style={{backdropFilter: 'blur(3px)'}}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          clearForm()
        }}
      >
        <div
          className='modal-dialog'
          style={{minWidth: '50%'}}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <div className='modal-content'>
            <div className='modal-header'>
              <h3 className='modal-title'> {intl.formatMessage({id:'Edit Scan'})}</h3>
              {<p style={{color: 'red'}}>{error}</p>}
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={clearForm}
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon-2 image_change_theme2'
                />
              </div>
            </div>

            <div className='modal-body'>
              <form
                className='w-100 form'
                onSubmit={formik.handleSubmit}
                noValidate
                id='kt_login_signin_form'
              >
                {!loading ? (
                  <>
                    <div className='fv-row mb-10'>
                      <label className='form-label fs-6 fw-bolder'>{intl.formatMessage({id: 'Description'})}</label>
                      <textarea
                        placeholder={intl.formatMessage({id: 'Description'})}
                        {...formik.getFieldProps('description')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid': formik.touched.description && formik.errors.description,
                          },
                          {
                            'is-valid': formik.touched.description && !formik.errors.description,
                          }
                        )}
                        name='description'
                        autoComplete='off'
                      />
                      {formik.touched.description && formik.errors.description && (
                        <div className='fv-plugins-message-container mt-2 text-danger'>
                          <span role='alert'>{formik.errors.description}</span>
                        </div>
                      )}
                    </div>
                    {process.env.REACT_APP_MODE === 'demo'&&<p className='text-danger'> {intl.formatMessage({id: 'This feature is not available in demo mode'})}</p>}
                      <button
                        type='submit'
                        id='kt_sign_in_submit'
                        className='btn btn-success'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                        disabled={process.env.REACT_APP_MODE === 'demo'}
                      >
                         {intl.formatMessage({id:'Save'})}
                      </button>
                  </>
                ) : (
                  <PageLoading />
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
})
export default EditModal
