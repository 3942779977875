import React from 'react'
// import ChartLine from '../../ChartLine'
import {useIntl} from 'react-intl'
import ChartLine from './ChartLine'
import ColumnChart from './ColumnChart'

export default function Chart ({list = [], type ='line'}: {list: any[]; type: 'line' | 'column'}) {
  const intl = useIntl()
  return (
    <>
      <hr />
      <div className='d-flex justify-content-between gap-5' style={type === 'line'?{padding: 10}:{padding:'0 10px 0 10px'}}>
        {list.map((item, ind) => (
          <div className='d-flex gap-2 align-center w-50' key={ind}>
            <div className='d-flex flex-column justify-content-center'>
              <span>{intl.formatMessage({id: item.title})}</span>
              <strong style={{fontSize: 18}}>{item.count}</strong>
            </div>
            {type === 'line' ? <ChartLine data={item.data} /> : <ColumnChart data={item.data} />}
          </div>
        ))}
      </div>
    </>
  )
}
