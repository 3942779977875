import React, {useState, useEffect, useContext, useLayoutEffect} from 'react'
import axios from 'axios'
import ScanTable from './components/ScanTable'
import PageLoading from '../loading/PageLoading'
// import ReactPaginate from 'react-paginate'
import useErrorHandling from '../../../Utils/useErrorHandling'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../setup/redux/Store'
import {ChangeUserStatus, SetDataScan, SetDataScanDAG} from '../../modules/auth'
import ReactPaginate from 'react-paginate'
import EmptyPage from '../emptyPage/EmptyPage'
import {ColumnChart} from './components/ColumnChart'
import {LineChart} from './components/LineChart'
import DAG from './components/DAG'
import Arr001 from '../components/Arr001'
import {TaskScan} from './components/TaskScan'
import {useIntl} from 'react-intl'
import {I18nContext} from '../../../_metronic/i18n/Metronici18n'
import {useNavigate} from 'react-router-dom'
import Joyride, {CallBackProps} from 'react-joyride'
const steps = [
  {
    target: '.service-feature-one',
    content: 'این ویژگی 1 در صفحه سرویس است.',
  },
  {
    target: '.service-feature-two',
    content: 'این ویژگی 2 در صفحه سرویس است.',
  },
  {
    target: '.service-feature-three',
    content: 'این ویژگی 3 در صفحه سرویس است.',
  },
]
const chartData = {
  x: ['2024/10/10', '2024/10/11', '2024/10/12', '2024/10/13'],
  y: [
    77, 105, 87, 106, 11, 108, 63, 100, 66, 107, 88, 117, 86, 111, 88, 163, 60, 166, 77, 118, 87,
    186, 11, 118, 63, 110, 66, 117, 88, 117, 86, 111, 88, 113, 60, 116,
  ],
}
const leftChart = {
  x: ['2024/10/10', '2024/10/11', '2024/10/12', '2024/10/13', '2024/10/14'],
  y: [
    {
      name: 'string',
      data: [11, 15, 18, 22, 33],
    },
    {
      name: 'string2',
      data: [3, 8, 11, 18, 25],
    },
  ],
}
const rightChart = {
  x: ['2024/10/10', '2024/10/11', '2024/10/12', '2024/10/13', '2024/10/14'],
  y: [
    {
      name: 'string',
      data: [11, 15, 18, 22, 33],
    },
  ],
}
export const Scan = () => {
  const [loading, setLoading] = useState(false)
  const [idSelected, setIdSelected] = useState<null | number>(null)
  const isUserNew = useSelector((state: RootState) => state.root.user?.is_user_new)
  const scanDagData = useSelector((state: RootState) => state?.root?.data?.dataScanDAG)
  const scanData = useSelector((state: RootState) => state?.root?.data?.dataScan)
  const dispatch = useDispatch()
  const errorHandling = useErrorHandling()
  const intl = useIntl()
  const lang = useContext(I18nContext)?.lang.dir
  const [ind, setInd] = useState<undefined | number>()
  const [run, setRun] = useState(false)
  const changeUserStatus = async () => {
    try {
      const data = await axios.patch(
        `${process.env.REACT_APP_API_ENDPOINT}/auth/users/set_new/`,
        {}
      )
      errorHandling(data.status)
      if (data.status >= 200 && data.status < 300) {
        dispatch(ChangeUserStatus(false))
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/auth/users/set_new/ \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }
  const handleJoyrideCallback = (data: CallBackProps) => {
    const {status} = data

    if (status === 'finished' || status === 'skipped') {
      // changeUserStatus()
      dispatch(ChangeUserStatus(false))
      setRun(false)
    }
  }

  // const handlePageClick = (event: {selected: number}) => {
  //   fetchScanData(event.selected + 1)
  // }
  const fetchDagsData = async () => {
    setLoading(true)
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/workflow/`)
      errorHandling(data.status)
      if (data.status >= 200 && data.status < 300) {
        dispatch(SetDataScanDAG(data.data))
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/workflow/ \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  const fetchScanData = async (page = 1) => {
    setLoading(true)
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/scan/`)
      errorHandling(data.status)
      if (data.status >= 200 && data.status < 300) {
        dispatch(SetDataScan(data.data))
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/scan/?page=${page} \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  useLayoutEffect(() => {
    if (isUserNew) {
      setRun(true)
      setInd(0)
    }
    const nowDate = new Date().getTime()
    if (scanData?.expireTime && scanData.expireTime >= nowDate && scanData?.data) {
    } else {
      fetchScanData()
    }
    if (scanDagData?.expireTime && scanDagData.expireTime >= nowDate && scanDagData?.data) {
    } else {
      fetchDagsData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <>
        <div className='w-100 d-flex gap-5'>
          <div className='w-50 card' style={{minWidth: 540}}>
            <div className='service-feature-one'>
              <div className='h-300px p-3 pt-5'>
                <div className='w-100 h-30px d-flex justify-content-between'>
                  <h3>{intl.formatMessage({id: 'Total Scans'})}</h3>
                  <div
                    className='d-flex align-items-center gap-3'
                    // style={!!!scanData.data?.length ? {filter: 'blur(3px)'} : {}}
                    style={{filter: 'blur(3px)'}}
                  >
                    <div className='w-40px h-30px bg-gray-300 d-flex align-items-center justify-content-center gap-1 p-2'>
                      <div
                        className='w-8px h-8px rounded-pill'
                        style={{backgroundColor: '#F15B46'}}
                      ></div>
                      <span>18</span>
                    </div>
                    <div className='w-40px h-30px bg-gray-300 d-flex align-items-center justify-content-center gap-1 p-2'>
                      <div
                        className='w-8px h-8px rounded-pill'
                        style={{backgroundColor: '#02694a'}}
                      ></div>
                      <span>18</span>
                    </div>
                  </div>
                </div>
                <div
                  style={
                    // !!!scanData.data?.length
                    //   ? {filter: 'blur(3px)', height: '100%'}
                    //   : {height: '100%'}
                    {filter: 'blur(3px)', height: '100%'}
                  }
                >
                  <ColumnChart chartData={chartData} />
                </div>
              </div>
            </div>
            <hr className='mx-5' />
            <div className='service-feature-three h-100'>
              <ScanTable
                getLoading={loading}
                setIdSelected={setIdSelected}
                idSelected={idSelected}
                scanData={scanData?.data}
                dagsData={scanDagData?.data}
                getData={fetchScanData}
              />
            </div>
            <div className='d-flex justify-content-end bg-body pb-4 pe-4 rounded-bottom'>
              {/* <ReactPaginate
              nextLabel={`${intl.formatMessage({id: 'Next'})} >`}
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={scanData?.total_pages}
              forcePage={scanData?.current_page - 1}
              previousLabel={`< ${intl.formatMessage({id: 'Previous'})}`}
              pageClassName='page-item'
              pageLinkClassName='page-link'
              previousClassName='page-item'
              previousLinkClassName='page-link'
              nextClassName='page-item'
              nextLinkClassName='page-link'
              breakLabel='...'
              breakClassName='page-item'
              breakLinkClassName='page-link'
              containerClassName='pagination'
              activeClassName='active'
              renderOnZeroPageCount={null}
            /> */}
            </div>
          </div>
          <div className='w-50' style={{minWidth: 540}}>
            <div className='service-feature-two'>
              <div
                className='w-100 position-relative h-200px inner_box'
                style={idSelected !== null ? {transform: 'rotateX(180deg)'} : {}}
              >
                <div className='w-100  d-flex gap-5 front_card '>
                  <div className='w-50 card p-3 py-5' style={{height: 'fit-content'}}>
                    <h2>{intl.formatMessage({id: 'Task Runs'})}</h2>
                    <div
                      className='d-flex align-items-center gap-5 h-100px mt-2'
                      // style={!!!scanData.data?.length ? {filter: 'blur(3px)'} : {}}
                      style={{filter: 'blur(3px)'}}
                    >
                      <p
                        style={{
                          fontSize: 45,
                          margin: 0,
                          height: 'fit-content',
                          minWidth: 123,
                          textAlign: 'center',
                        }}
                      >
                        23
                      </p>
                      <LineChart chartData={leftChart} />
                    </div>
                  </div>
                  <div className='w-50 card p-3 py-5' style={{height: 'fit-content'}}>
                    <h2>{intl.formatMessage({id: 'Scan Duration'})}</h2>
                    <div
                      className='d-flex align-items-center gap-5 h-100px mt-2'
                      // style={!!!scanData.data?.length ? {filter: 'blur(3px)'} : {}}
                      style={{filter: 'blur(3px)'}}
                    >
                      <p
                        style={{
                          fontSize: 45,
                          margin: 0,
                          height: 'fit-content',
                          textWrap: 'nowrap',
                          minWidth: 123,
                          textAlign: 'center',
                        }}
                      >
                        32min
                      </p>
                      <LineChart chartData={rightChart} />
                    </div>
                  </div>
                </div>
                <div
                  className='w-100 h-200px card  back_card position-relative'
                  style={{filter: 'blur(3px)'}}
                >
                  <button
                    className={`col-auto border border-0   p-0 m-0 position-absolute `}
                    style={
                      lang && lang === 'rtl'
                        ? {rotate: '180deg', background: 'unset', top: 10, right: 10, zIndex: 100}
                        : {rotate: '180deg', background: 'unset', top: 10, left: 10, zIndex: 100}
                    }
                    onClick={() => {
                      setIdSelected(null)
                    }}
                  >
                    <Arr001 fill={'#fff'} className={'image_change_theme'} />
                  </button>
                  <DAG />
                </div>
              </div>
            </div>
            <div className='w-100  card mt-5'>
              <div className='w-100  d-flex  flex-row justify-content-between align-items-start p-5'>
                <h3 className=' '>{intl.formatMessage({id: 'Vulnerabilities'})}</h3>
              </div>
              {idSelected !== null ? (
                <TaskScan vulnerabilityData={scanData.data[idSelected].vulnerabilities} />
              ) : (
                <div className='h-400px d-flex align-items-center justify-content-center gap-5'>
                  <Arr001
                    fill={'#fff'}
                    className={'image_change_theme '}
                    style={{rotate: '180deg'}}
                  />
                  {!!!scanData.data?.length ? (
                    <h2>{intl.formatMessage({id: 'Please Create a scan first'})}</h2>
                  ) : (
                    <h2>{intl.formatMessage({id: 'Please select scan'})}</h2>
                  )}
                </div>
              )}
              {/* <EmptyPage/> */}
            </div>
          </div>
        </div>
        {/* Joyride Component */}
        <Joyride
          stepIndex={ind}
          steps={steps}
          run={run}
          disableOverlay={false}
          disableScrolling={true}
          showProgress={true}
          disableOverlayClose={true}
          continuous={true}
          showSkipButton={true}
          callback={handleJoyrideCallback}
          locale={{next: 'بعدی', skip: 'رد کردن'}}
          spotlightPadding={5}
          styles={{
            options: {
              zIndex: 10000, // Ensure Joyride is above other elements
            },
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.8)', // Dark overlay on the whole page
            },
          }}
        />
      </>
    </>
  )
}
