/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {memo, useEffect, useRef} from 'react'
import ApexCharts from 'apexcharts'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../setup/redux/Store'

// import { ApexOptions }  from 'apexcharts'

const getAmount = (percent: string) => {
  switch (true) {
    case percent === 'A':
      return 90
    case percent === 'B':
      return 70
    case percent === 'C':
      return 50
    case percent === 'D':
      return 30
    default:
      return 10
  }
}
const getColor = (percent: string) => {
  switch (true) {
    case percent === 'A':
      return '#008000'
    case percent === 'B':
      return '#0000FF'
    case percent === 'C':
      return '#FFFF00'
    case percent === 'D':
      return '#FF7F7F'
    default:
      return '#FF0000'
  }
}

const HalfCircleChart = memo(
  ({title = 'Total Grade', percent}: {title: string; percent: string|null}) => {
    const user = useSelector((state:RootState)=>state.root.user)
    const chartRef = useRef(null)
    const color = getColor(percent||"A")
    const amount = getAmount(percent||"A")
    useEffect(() => {
      if (!chartRef.current) {
        return
      }

      const chart = new ApexCharts(chartRef.current, option2)
      if (chart) {
        chart.render()
      }

      return () => {
        if (chart) {
          chart.destroy()
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartRef,percent])

    const option2 = {
      chart: {
        type: 'radialBar',
        height: 250,
        width: 250,
        offsetY: -20,
        offsetX:0,
      },
      series: [amount],
      colors: [color],
      labels: [title],
      stroke: {
        lineCap: 'round',
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,

          dataLabels: {
            show: true,
            name: {
              offsetY: 10,
              show: true,
              color: 'var(--bs-gray-700)',
              fontSize: '12px',
            },
            value: {
              formatter: function () {
                return percent
                // return grade
              },
              offsetY: -30,
              color: 'var(--bs-gray-900)',
              fontSize: '24px',
              fontWeight: 'bold',
              show: true,
            },
          },

          track: {
            background: 'var(--bs-gray-500)',
            strokeWidth: '85%',
          },
        },
      },
      responsive: [
        {
          breakpoint: 1300,
          options: {
            chart: {
              height: 150,
            },
            plotOptions: {
              radialBar: {
                dataLabels: {
                  show: true,
                  name: {
                    offsetY: 10,
                    fontSize: '10px',
                  },
                  value: {
                    formatter: function () {
                      return percent
                      // return parseInt(val)
                    },
                    offsetY: -25,
                    fontSize: '14px',
                  },
                },
              },
            },
          },
        },
      ],
    }
    return (
      <div className='position-relative' style={!percent&&!user?.is_user_scan ?{filter:'blur(3px)'}:{}} >
        <div ref={chartRef} id='chart1' />
      </div>
    )
  }
)

export default HalfCircleChart
