import axios from 'axios'
import React, {useState} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useNavigate, useParams} from 'react-router-dom'

export default function VerifyEmail () {
  const [loading, setLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [status, setStatus] = useState('')
  const params = useParams()
  const {uid, token} = params
  const navigate = useNavigate()
  console.log(params)
  async function verify () {
    setLoading(true)
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/auth/users/activation/`,
        {
          uid,
          token,
        },
        {withCredentials: false}
      )
      if (data?.status >= 200 && data.status < 300) {
        setStatus('Your email has been successfully verified')
        setIsError(false)
        setTimeout(() => {
          navigate('/auth/login')
        }, 5000)
      } else {
        setIsError(true)
        setStatus(data.data.message || 'Unfortunately, there was a problem verifying your email')
      }
    } catch (e: any) {
      console.log('aaa', e?.message)
      setIsError(true)
      setStatus(e?.message || 'Unfortunately, there was a problem verifying your email')
    } finally {
      setLoading(false)
    }
  }
  return (
    <div
      className='vw-100 vh-100 d-flex align-items-center justify-content-center text-white flex-column'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/bg-1.jpg')})`,
        backgroundSize: 'cover',
      }}
    >
      <a href='#' className='mb-2'>
        <img alt='Logo' src={toAbsoluteUrl('/splashLogo.png')} className='h-100px' />
      </a>

      <div
        hidden={!status}
        className={`mb-lg-15 h-40px alert ${
          isError ? 'alert-danger' : 'alert-success'
        }  rounded-pill`}
      >
        <div className='font-weight-bold alert-text'>{status}</div>
      </div>

      <div
        className='d-flex align-items-center justify-content-center flex-column gap-10  p-5 rounded border  border-gray-700 shadow-lg text-white'
        style={{background: '#8a8a8a45'}}
      >
        <h1 className='text-white'>Verify your email to finish signing up</h1>

        <h4 className='text-white'>
          Thank you for choosing<strong> NetBaan Sharif</strong>.
        </h4>
        <h6 className='text-white'>Please confirm your email by clicking the button below</h6>
        <button
          className='btn btn-pill border border-white rounded-pill btn-outline-white font-weight-bold text-white  px-15 py-3'
          style={{opacity: 0.9}}
          disabled={loading}
          onClick={verify}
        >
          {!loading && <span className='indicator-label'>Verify</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border-sm spinner-border align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </div>
  )
}
