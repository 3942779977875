import React, {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {PRICE_PLAN_CARD_DATA, PRICE_PLAN_TABLE} from '../../modules/auth/redux/DEMO_DATA'
import Joyride, {CallBackProps} from 'react-joyride'
import {useSelector} from 'react-redux'
import {RootState} from '../../../setup/redux/Store'
import Modal from './Modal'
import axios from 'axios'
import useErrorHandling from '../../../Utils/useErrorHandling'
import {SetDataPricePlans} from '../../modules/auth'
import {useDispatch} from 'react-redux'
import { useIntl } from 'react-intl'

const steps = [
  {
    target: '.service-feature-one',
    content: 'این ویژگی 1 در صفحه سرویس است.',
  },
  {
    target: '.service-feature-two',
    content: 'این ویژگی 2 در صفحه سرویس است.',
  },
  {
    target: '.service-feature-three',
    content: 'این ویژگی 3 در صفحه سرویس است.',
  },
  {
    target: '.service-feature-4',
    content: 'این ویژگی 4 در صفحه سرویس است.',
  },
  {
    target: '.service-feature-5',
    content: 'این ویژگی 5 در صفحه سرویس است.',
  },
]

export default function PricePlan () {
  const intl = useIntl()
  const isUserNew = useSelector((state: RootState) => state.root.user?.is_user_new)
  const pricePlanData = useSelector((state: RootState) => state.root.data.dataPricePlans)
  const [period, setPeriod] = useState<'mon' | 'ann'>('mon')
  const [details, setDetails] = useState<any>()
  
  const [ind, setInd] = useState<undefined | number>()
  const [run, setRun] = useState(false)
  const navigate = useNavigate()
  const errorHandling = useErrorHandling()
  const dispatch = useDispatch()
  const handleJoyrideCallback = (data: CallBackProps) => {
    const {status} = data

    // When the tour is finished, redirect to the scan Page
    if (status === 'finished' || status === 'skipped') {
      setRun(false)
      navigate('/scan')
    }
  }
  const fetchPricePlans = async () => {
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/plans/`)
      errorHandling(data.status) //logout when invalid token && redirect 404
      if (data.status >= 200 && data.status < 300) {
        dispatch(SetDataPricePlans(data.data))
        console.log(data.data)
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/plans/ \n`,
          data
        )
      }
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    const nowDate = new Date().getTime()
    if (pricePlanData?.expireTime && pricePlanData.expireTime >= nowDate) {
    } else {
      fetchPricePlans()
    }
    if (isUserNew) {
      setRun(true)
      setInd(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <div className=' card p-5'>
        {/* title */}
        <div className='service-feature-one'>
          <h1 className='fs-2hx fw-bold mb-5 text-center'> {intl.formatMessage({id: 'Choose Your Plan'})}</h1>
          <div className='text-gray-600 fw-semibold fs-5 text-center'>
          {intl.formatMessage({id: 'If you need more info about our pricing, please check'})}
            {' '}
            <Link to={'/'} className='link-primary fw-bold'>
            {intl.formatMessage({id: 'Pricing Guidelines'})}
              
            </Link>
            .
          </div>
        </div>
        {/* period  */}
        {/* <div
          className='nav-group nav-group-outline mx-auto mt-13 mb-15 d-flex gap-2 '
          data-kt-buttons='false'
          // data-kt-initialized='1'
        >
          <div className='service-feature-two'>
            <button
              className={`btn btn-color-gray-600 btn-active btn-active-secondary px-6 py-3 ${
                period === 'mon' && 'active'
              }`}
              data-kt-plan='month'
              onClick={() => setPeriod('mon')}
            >
              Monthly
            </button>
          </div>
          <div className='service-feature-three'>
            <button
              className={`btn btn-color-gray-600 btn-active btn-active-secondary px-6 py-3 ${
                period === 'ann' && 'active'
              }`}
              data-kt-plan='annual'
              onClick={() => setPeriod('ann')}
            >
              Annual
            </button>
          </div>
        </div> */}
        {/* plan card */}
        <div className='row g-10 mt-10'>
          {(pricePlanData.data?.length ? pricePlanData.data : PRICE_PLAN_CARD_DATA).map(
            (items: any, ind: number) => (
              <div
                className='col-xl-4'
                key={items.id}
                style={!pricePlanData.data?.length ? {filter: 'blur(3px)'} : {}}
              >
                <div className='service-feature-4'>
                  <div className='d-flex h-100 align-items-center'>
                    <div className='w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-15 px-10'>
                      <div className='mb-7 text-center d-flex flex-column align-items-center'>
                        <h1 className='text-gray-900 mb-5 fw-bolder text-capitalize'>
                          {items.name}
                        </h1>
                        <div
                          className='text-gray-600 fw-semibold mb-5'
                          style={{
                            maxWidth: '25ch',
                            wordWrap: 'break-word',
                            overflowWrap: 'break-word',
                            whiteSpace: 'normal',
                          }}
                        >
                          {items.description}
                        </div>
                        {!!items.discounted_price && (
                          <span
                            className=' fw-bold  text-gray-600'
                            style={{textDecoration: 'line-through',fontSize:20}}
                          >
                            { items.price}
                          </span>
                        )}
                        <div className='text-center d-flex align-items-end'>
                          <span className='mb-2 text-primary'>{`﷼`}</span>
                          <span className='fs-2x fw-bold text-primary'>
                            {period === 'mon'
                              ? items.discounted_price
                                ? items.discounted_price
                                : items.price
                              : items.discounted_price
                              ? items.discounted_price
                              : items.price}
                          </span>
                          <span className='fs-7 fw-semibold opacity-50 mb-2'>
                            /
                            <span data-kt-element='period' className=' text-capitalize'>
                              {items.duration_days} {intl.formatMessage({id: 'day'})}
                            </span>
                          </span>
                        </div>
                      </div>

                      <div className='w-100 mb-10'>
                        {PRICE_PLAN_CARD_DATA[ind].features?.map((item, index) => (
                          <div className='d-flex align-items-center mb-5' key={index}>
                            <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3'>
                              {item.title}
                            </span>
                            {item.status ? (
                              <i className='ki-duotone ki-check-circle fs-1 text-success'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                              </i>
                            ) : (
                              <i className='ki-duotone ki-cross-circle fs-1'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                              </i>
                            )}
                          </div>
                        ))}
                      </div>
                      <button
                        onClick={() => {
                          setDetails(items)
                        }}
                        className='btn btn-sm btn-primary'
                        type='button'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_edit'
                        disabled={!pricePlanData.data?.length}
                      >
                        {intl.formatMessage({id: 'Select'})}
                        
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
        {/* plan table */}
        <div className='service-feature-5'
        style={{filter:'blur(3px)'}}
        >
          <table className='table align-middle table-striped gy-7 mt-7'>
            <tbody>
              <tr>
                <th className='card-rounded-start'>
                  <div className='fw-bolder d-flex align-items-center ps-9 fs-3'>
                  {intl.formatMessage({id: 'Feature'})}
                    </div>
                </th>
                {PRICE_PLAN_CARD_DATA.map((item, ind, arr) => (
                  
                    <td key={ind} className={`${ind === arr.length - 1 && 'card-rounded-end'}`}>
                      <div className='flex-root d-flex justify-content-center text-capitalize fs-3 fw-bolder'>
                        {item.name}
                      </div>
                    </td>
                  
                ))}
              </tr>

              {PRICE_PLAN_TABLE.map((item, ind) => (
                <tr key={ind}>
                  <th className={'card-rounded-start'}>
                    <div className='fw-bolder d-flex align-items-center ps-9 fs-3'>
                      {item.title}
                    </div>
                  </th>
                  {item.data.map((i, index, arr) => (
                    <td
                    key={index}
                      className={`${index === arr.length - 1 ? 'card-rounded-end ' : 'no_reduce'}`}
                    >
                      <div className='flex-root d-flex justify-content-center'>
                        {i ? (
                          <i className='ki-duotone ki-check-circle fs-1 text-success'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                          </i>
                        ) : (
                          <i className='ki-duotone ki-cross-circle fs-1'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                          </i>
                        )}
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* Joyride Component */}
      <Joyride
        stepIndex={ind}
        steps={steps}
        run={run}
        disableOverlay={false}
        disableScrolling={true}
        showProgress={true}
        disableOverlayClose={true}
        continuous={true}
        showSkipButton={true}
        callback={handleJoyrideCallback}
        locale={{next: 'بعدی', skip: 'رد کردن'}}
        spotlightPadding={5}
        styles={{
          options: {
            zIndex: 10000, // Ensure Joyride is above other elements
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)', // Dark overlay on the whole page
          },
        }}
      />
      <Modal details={details} setDetails={setDetails}  />
    </>
  )
}
