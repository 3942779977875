/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {memo, useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'
interface ChartData {
  x: string[]
  y: number[] 
}
type Props = {
  chartData: ChartData,
  color?:string
}

const ColumnChart: React.FC<Props> = memo(({color='#02694a' ,chartData}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!chartRef.current) {
      return
    }
    const chart = new ApexCharts(chartRef.current, getChartOptions(color,chartData))
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, chartData])

  return (
    <div
      ref={chartRef}
      id='kt_charts_widget_8_chart'
      style={{height: '100%'}}
      // className='p-0'
    />
  )
})

export {ColumnChart}

function getChartOptions (color:string,chartData: ChartData): ApexOptions {
  const labelColor = '#474761'

  const strokeColor = '#000'

  const color1 = getCSSVariableValue('--bs-primary')

  const color2 = getCSSVariableValue('--bs-success')

  const color3 = getCSSVariableValue('--bs-primary')

  return {
    series: [{data: chartData.y, name: 'Net Profit'}],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      width: '100%',
      height: '100%',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '4px',
        borderRadius: 2,
        borderRadiusApplication:'end',
        borderRadiusWhenStacked:'last',
        colors:{

          ranges: [{
            from: 91,
            to: 400,
            color: '#F15B46'
          }, {
            from: 0,
            to: 90,
            color: color
          }]
        }
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },

    xaxis: {
      offsetX: 0,
      offsetY: 0,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },

      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      opposite: true,
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      theme: 'dark',
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val + ' issues'
        },
      },
    },
    grid: {
      borderColor:labelColor,
      yaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        left: 10,
        right: 10,
        top: 0,
        bottom: 0,
      },
    },
    markers: {
      colors: [color1, color2, color3],
      strokeColors: [color1, color2, color3],
      strokeWidth: 0,
    },
  }
}
