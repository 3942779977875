import React from 'react'

export default function ImageModal({url}:{url:string}) {
  return url?(
    <div
    className='modal fade'
    id='exampleModal'
    tabIndex={-1}
    aria-labelledby='exampleModalLabel'
    aria-hidden='true'
  >
    <div className='modal-dialog modal-lg'>
      <div className='modal-content'>
        <div className='modal-header'>
          <button
            type='button'
            className='btn-close'
            data-bs-dismiss='modal'
            aria-label='Close'
          />
        </div>
        <div
          className='modal-body min-w-500px min-h-500px'
          style={{
            backgroundImage: `url(${url})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
          }}
        >
        </div>
      </div>
    </div>
  </div>
  ):null
}
