import React from 'react'

import DonutChart from './DonutChart'
import sample from '../../sample'
import InfoIcon from '../../images/InfoIcon'
import {Tooltip as ReactTooltip} from 'react-tooltip'
import {useIntl} from 'react-intl'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../setup/redux/Store'
const DefaultData = [1, 1, 1, 1, 1]
const IssueByStatus = ({data , loading}: {data: number[] , loading:boolean}) => {
  const intl = useIntl()
  const user = useSelector((state:RootState) => state.root.user)
  function findData () {
    return data?.some((number) => number > 0)
  }
  return (
    <div className='col-4' style={{height: 270}}>
      <div className={`card card-body h-100 bg-body ${loading&&'skeleton'}`}>
        <div className='d-flex flex-row align-items-center'>
          <h3 className='card-title align-items-start flex-column   m-0'>
            {intl.formatMessage({id: 'Assets By Grade'})}
          </h3>
          <span
            data-tooltip-id='my-tooltip-AssetsByGroup'
            data-tooltip-content={intl.formatMessage({id: 'Assets By Grade tooltip'})}
          >
            <InfoIcon />
          </span>
          <ReactTooltip
            id='my-tooltip-AssetsByGroup'
            style={{maxWidth: 250, fontSize: 12, textAlign: 'center', zIndex:100}}
          />
        </div>
        <div style={!!!findData()&& !user?.is_user_scan ?{filter:'blur(3px)'}:{}}>
        <DonutChart data={user?.is_user_scan?data:DefaultData} />
        </div>
      </div>
    </div>
  )
}

export default IssueByStatus
